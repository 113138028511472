

<ion-header *ngIf="mode === 'modal'">
  <ion-title>Documents</ion-title>
</ion-header>

<ion-content *ngIf="mode === 'modal'">
  <ng-container *ngTemplateOutlet="docList"></ng-container>
</ion-content>
<ng-container *ngIf="mode === 'standalone'">
  <ng-container *ngTemplateOutlet="docList"></ng-container>
</ng-container>

<ng-template #docList>
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-lg="3">
        <div class="filters-container">
          <ion-list>
            <ion-item>
              <ion-select label="Sort" [(ngModel)]="sortOption" (ionChange)="onChangeSort($event)" interface="popover">
                <ion-select-option *ngFor="let so of sortOptions" [value]="so">{{so}}</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-accordion-group>
              <ion-accordion *ngFor="let config of filterObjects" [value]="config.field">
                <ion-item slot="header">
                  <ion-label>{{config.label}}</ion-label>
                </ion-item>
                <div slot="content">
                  <ng-container *ngIf="config?.type === 'list'">
                    <ion-list>
                      <ng-container *ngFor="let option of filterOptions[config.field]">
                        <ion-checkbox 
                          labelPlacement="end" 
                          (ionChange)="toggleFilterValue(config.field, option.key)"
                          [checked]="searchParams['filters'][config.field]?.includes(option.key)"
                        >
                          <ng-container *ngIf="config?.field === 'country'">
                            <app-flag-icon [iso2]="option.label"></app-flag-icon>
                            {{countryService.getCountryName(option.label)}} : {{option.doc_count}}
                          </ng-container>
                          <ng-container *ngIf="config?.field !== 'country'">
                            {{option.label}} : {{option.doc_count}}
                          </ng-container>
                        </ion-checkbox><br />
                      </ng-container>
                    </ion-list>
                  </ng-container>
                </div>
              </ion-accordion>
            </ion-accordion-group>
          </ion-list>
        </div>
      </ion-col>
      <ion-col size-xs="12" size-lg="8">
  
        <div #tableRef class="doc-list-container">
          <div class="doc-list-body">
            <ng-container *ngIf="docsLoading">
              <ngx-skeleton-loader count="10"></ngx-skeleton-loader>
            </ng-container>
            <div class="docs-container" *ngIf="!docsLoading">
              <div *ngFor="let doc of hits" class="doc-wrapper">
                <div class="doc-container" [class.expanded]="doc?.expanded">
                  <div class="row1">
                    <i class="fa-duotone fa-microchip-ai ai-icon" [matTooltip]="'View AI-generated summary and applications for this document'" (click)="ms.openAiModal('document', {document: doc})"></i>
                    <div class="title" (click)="onClickDocument(doc)" [class.with-link]="doc?.link?.length">{{doc?.title || 'No Title' | titlecase}}</div>
                  </div>
                  <div class="orgs" *ngIf="doc?.orgs?.length">
                    <div class="org" *ngFor="let org of doc?.orgs; let first=first;">
                      <ng-container *ngIf="!first">&bull;</ng-container>
                      <app-flag-icon [iso2]="org?.iso2"></app-flag-icon> |
                      {{org?.org | titlecase}} | {{org?.country}}
                    </div>
                  </div>
                  <div class="row3">
                    <div class="doc-year">{{doc.year}}</div>&bull;
                    <div class="doc-type">{{docOriginLabels[doc.doc_origin]}}</div>&bull;
                    <div *ngIf="doc?.doc_origin.includes('grant')" class="award">{{doc.award_amount | tableValue: 'usd_'}}</div>
                    <div *ngIf="doc?.doc_origin === 'invention'">{{doc.award_amount | tableValue: 'usd_'}}</div>
                  </div>
                  <div class="row2">
                    <div class="supercluster">Supercluster: {{doc?.supercluster_name}}</div>
                    <div class="cluster">Cluster: {{doc?.cluster_name}}</div>
                  </div>
                  <div class="expanded-content" *ngIf="doc?.expanded">
                    <div class="abstract">{{doc?.abstract}}</div>
                  </div>
                  <div class="footer">
                    <div class="expand-toggle" (click)="doc.expanded = !doc?.expanded">
                      <span class="expand-toggle-label">
                        {{doc?.expanded ? 'Show Less' : 'Show More'}}
                      </span>
                      <cds-icon shape="angle" [direction]="doc?.expanded ? 'up' : 'down'"></cds-icon>
                      <!-- <mat-icon>{{doc?.expanded ? 'expand_less' : 'expand_more'}}</mat-icon> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
  
        <div class="paginator-container" *ngIf="mode !== 'modal'">
          <mat-paginator #paginator
            (page)="onPage($event)"
            [length]="total"
            [pageSize]="10"
            [pageIndex]="pageIndex"
            [showFirstLastButtons]="true"
            [pageSizeOptions]="[10, 25, 50, 100]"
          ></mat-paginator>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>


<ion-footer *ngIf="mode === 'modal'">
  <ion-toolbar>
    <div class="paginator-container">
      <mat-paginator #paginator
        (page)="onPage($event)"
        [length]="total"
        [pageSize]="10"
        [pageIndex]="pageIndex"
        [showFirstLastButtons]="true"
        [pageSizeOptions]="[10, 25, 50, 100]"
      ></mat-paginator>
    </div>
  </ion-toolbar>
</ion-footer>









