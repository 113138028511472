<ion-header>
  <ion-toolbar>
    <ion-title>
      Create You Free Account to Get Started
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-item>
    <ion-label class="ion-text-wrap">
      With a free account, you  the CHN Playgrounds. 
    </ion-label>
    <ion-buttons slot="end">
      <ion-button (click)="ms.openPlaygroundInfoModal()">Learn More</ion-button>
    </ion-buttons>
  </ion-item>
  <ion-item>
    <ion-label class="ion-text-wrap">
      To get started using Explorer, Scout, or any other CHN products, you  start a 7-day free trial.
    </ion-label>
    <ion-buttons slot="end">
      <ion-button (click)="ms.openTrialDetailsModal()">Learn More</ion-button>
    </ion-buttons>
  </ion-item>
</ion-content>
<ion-footer>
  <ion-item color="success" button (click)="close()" style="text-align: center !important;">
    <ion-label>GOT IT!</ion-label>
  </ion-item>
  <!-- <div class="buttons-container">
    <ion-button (click)="close()" color="success">Got it!</ion-button>
  </div> -->
</ion-footer>