import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { FunctionsService } from './functions.service';
import { AuthService } from './auth.service';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, map, skipWhile, startWith } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { CustomerService } from './customer.service';
import { SearchService } from './search.service';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
@Injectable({
  providedIn: 'root'
})
export class AppService {
  public isNative = false;
  public isSmall = false;
  public mobileView$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public mobileView = false;
  public avatarUrl$: Observable<string>;
  public thumbnailUrl$: Observable<string>;
  public bannerUrl$: Observable<string>;
  // public darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  darkMode$: Observable<boolean>;
  // public darkMode$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  theme = '';
  deviceInfo: any;

  constructor(
    private firestoreService: FirestoreService,
    private functionsService: FunctionsService,
    private authService: AuthService,
    private customerService: CustomerService,
    public platform: Platform,
    private searchService: SearchService,
  ) {

    this.isNative = Capacitor.isNativePlatform()
    platform.ready().then(() => {
      this.updatePlatformSize();
      /**
       * This will broadcast out any time the window size changes
       */
      platform.resize.subscribe(() => {
        this.updatePlatformSize();
      })
    });


    Device.getInfo().then(info => {
      Device.getId().then(deviceId => {
        this.deviceInfo = {
          deviceId,
          name: info.name || deviceId,
          platform: info.platform,
          operatingSystem: info.operatingSystem,
          osVersion: info.osVersion
        };
        console.log('device info', this.deviceInfo);
      })
    })
  }

  appInit() {
    return new Promise(async (resolve, reject) => {
      console.log('initing auth');
      await Promise.all([
        this.authService.appInit(),
        this.firestoreService.appInit(),
        this.functionsService.appInit(),
        this.customerService.appInit(),
        this.searchService.appInit(),
      ]);

      this.darkMode$ = this.authService.currentUser$.pipe(
        startWith({settings: {general: {darkMode: this.prefersDarkMode}}}),
        map(cu => cu && cu?.settings ? cu.settings.general.darkMode : true),
      );

      this.darkMode$.subscribe(dm => {
        if (dm && !document.body.classList.contains('dark')) {
          document.body.classList.add('dark');
        } else if (!dm && document.body.classList.contains('dark')) {
          document.body.classList.remove('dark');
          // document.body.classList.toggle('dark', this.darkMode$.getValue());
        }
        this.theme = dm ? 'dark' : ''
      })
      resolve(null);
    });
  }

  async toggleDarkMode() {
    // this.darkMode = !this.darkMode;
    try {
      await updateDoc(doc(getFirestore(), `user_settings/${this.authService.currentUser.fbUser.uid}`), {
        'general.darkMode': !this.authService.currentUser.settings.general.darkMode
      });
    } catch (err) {
      console.error('Error updating darkMode setting', err);
    }
    // this.darkMode$ will update when settings document subscription fires
  }

  updatePlatformSize() {
    this.isSmall = this.platform.width() <= 768;
    this.mobileView$.next(this.isSmall || this.isNative);
    this.mobileView = this.mobileView$.getValue();
    // console.log(`viewSize changed -- width: ${this.platform.width()} height: ${this.platform.height()} native: ${Capacitor.isNativePlatform()} mobileView: ${this.mobileView$.getValue()}`);
  }
}
