import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from '@app/core/backend.service';
import { SearchService } from '@app/core/search.service';
import { IUserSearch } from '@app/models/app_models/search.model';
import { IonicModule, LoadingController, ModalController } from '@ionic/angular';

@Component({
  standalone: true,
  imports: [IonicModule,CommonModule],
  selector: 'app-user-searches-modal',
  templateUrl: './user-searches-modal.component.html',
  styleUrls: ['./user-searches-modal.component.scss'],
})
export class UserSearchesModalComponent  implements OnInit {
  searches: IUserSearch[] = [];
  constructor(
    private backend: BackendService,
    public ss: SearchService,
    private ionModal: ModalController,
    private loadingController: LoadingController,
    private router: Router,
  ) {}

  ngOnInit() {
    this.backend.post_route<{userSearches: IUserSearch[]}>('user/get_user_searches', {limit: 10000}).then(res => {
      this.searches = res.userSearches;
    })
  }

  cancel() {
    return this.ionModal.dismiss(null, 'cancel');
  }

  async onSelectClusterSearch(search: IUserSearch) {
    await this.ss.loadSearch(search.uid);
    // this.router.navigate(['/explorer',search.uid]);
  }

  async onDeleteSearch(search_id: string) {
    event.stopPropagation();
    await this.ss.deleteSearch(search_id);
  }

}
