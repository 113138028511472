import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CustomerService } from '@app/core/customer.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureActivateGuard implements CanActivate {
  constructor(
    private router: Router,
    private cs: CustomerService,
  ) {
    console.log('product guard constructor');
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // let productName: string;
      // console.log('in guard', route.url, route.data);
      return this.cs.canAccessFeatures(route.data.features_required);
      // return true;
    }
  
}
