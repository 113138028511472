import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomerService } from '@app/core/customer.service';
import { SearchService } from '@app/core/search.service';
import { NgLetModule } from '@app/directives/ng-let/ng-let.module';
import { IUserSearch } from '@app/models/app_models/search.model';
import { IonicModule, LoadingController, ModalController } from '@ionic/angular';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, NgLetModule, ],
  selector: 'app-search-menu-modal',
  templateUrl: './search-menu-modal.component.html',
  styleUrls: ['./search-menu-modal.component.scss'],
})
export class SearchMenuModalComponent  implements OnInit {

  saveNameLoading = false;

  constructor(
    public ss: SearchService,
    private loadingController: LoadingController,
    public router: Router,
    private mc: ModalController,
    private cs: CustomerService,
  ) { }

  ngOnInit(
  ) {
  }

  async onUpdateName(newName: string) {
    if (!newName || !newName.length || newName === this.ss.currentSearch.name) {
      return;
    }
    const loader = await this.loadingController.create({
      message: 'Updating Search Name',
    });
    await loader.present();
    try {
      await this.ss.updateCurrentSearch({name: newName});
    } catch (err) {
      console.log('error updating search');
    } finally {
      await loader.dismiss();
    }
  }

  onSelectSearch(s: IUserSearch) {
    if (this.ss.currentSearch && this.ss.currentSearch.uid && s.uid === this.ss.currentSearch.uid) {
      return;
    }
    this.cs.featureCheck('explorer').then(canAccess => canAccess ? this.ss.setCurrentSearch(s.uid) : null);
  }

  onNewSearch() {
    this.mc.dismiss();
    this.cs.featureCheck('explorer').then(canAccess => canAccess ? this.router.navigate(['/explorer','new-search']) : null);
  }
  

}
