import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/core/auth.service';
import { IUserSettings } from '@app/models/user.model';
import { IonicModule, ModalController } from '@ionic/angular';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule],
  selector: 'app-cookie-modal',
  templateUrl: './cookie-modal.component.html',
  styleUrls: ['./cookie-modal.component.scss'],
})
export class CookieModalComponent  implements OnInit {

  constructor(
    private auth: AuthService,
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  async cancel() {
    await updateDoc(doc(getFirestore(), `user_settings/${this.auth.currentUser.fbUser.uid}`), {
      hasBeenPromptedForCookies: true,
      hasAcceptedCookies: true,
    } as Partial<IUserSettings>)
    this.modalController.dismiss();
  }

}
