import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { InfoTooltipComponent } from '../info-tooltip/info-tooltip.component';
import { IonModal, IonNav, ModalController, NavController } from '@ionic/angular';
import { FieldMap, IField } from '@app/models/app_models/fieldMap.model';
import { ItemReorderEventDetail } from '@ionic/angular';
import { ColumnSelectorHomeComponent } from './column-selector-home/column-selector-home.component';
import { ColumnSelectorFieldListComponent } from './column-selector-field-list/column-selector-field-list.component';

@Component({
  selector: 'app-column-selector-modal',
  templateUrl: './column-selector-modal.component.html',
  styleUrls: ['./column-selector-modal.component.scss'],
})
export class ColumnSelectorModalComponent implements OnInit {
  @ViewChild('nav') nav: IonNav;
  fieldMap = FieldMap;
  @Input() tableKey: string;
  @Input() availableColumns: string[];
  @Input() tableRef: any;
  columns: string[];
  columnGroupMap: {[section: string]: IField[]};


  rootComponent = ColumnSelectorHomeComponent;
  rootParams;

  constructor(
    private ionModal: ModalController,
  ) { }

  ngOnInit() {
    console.log('column selector params', this.tableRef);
    const columnGroupMap = {}
    // const columns = this.ss.userSettings$.getValue()[this.tableKey];
    const columns = [];
    this.availableColumns.forEach(c => {
      if (this.fieldMap[c]) {
        const section = this.fieldMap[c].section;
        if (!columnGroupMap[section]) {
          columnGroupMap[section] = [{
            ...this.fieldMap[c],
            visible: columns.includes(c)
          }]
        } else {
          columnGroupMap[section].push({
            ...this.fieldMap[c],
            visible: columns.includes(c)
          });
        }
      }
    });
    this.columnGroupMap = columnGroupMap;
    this.rootParams = {
      tableKey: this.tableKey,
      columnGroupMap: this.columnGroupMap,
      activeColumns: this.tableRef.activeColumns
    }
  }

  dismiss() {
    this.ionModal.dismiss();
  }

}
