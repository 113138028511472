<ion-header>
  <ion-toolbar>
    <ion-title>Subscription Required</ion-title>
    <ion-buttons slot="end">
      <ion-button color="medium" (click)="cancel()">CLOSE</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-item>
    <ion-label class="ion-text-wrap">
      {{message}}
    </ion-label>
    <ion-buttons slot="end">
      <ion-button color="success" (click)="goToProducts()">Start Free Trial</ion-button>
    </ion-buttons>
  </ion-item>
  <ion-item>
    <ion-label class="ion-text-wrap">
      CHN offers the CHN Playground, where all users can access limited version of our products for free!
    </ion-label>
    <ion-buttons slot="end">
      <ion-button color="primary" (click)="openPlayground()">View CHN Playground</ion-button>
    </ion-buttons>
  </ion-item>
</ion-content>