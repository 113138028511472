import { Component, OnInit } from '@angular/core';
import { DemoService } from '@app/core/demo.service';
import { IonNav, NavParams } from '@ionic/angular';
import { IField } from '@app/models/app_models/fieldMap.model';
import { SearchService } from '@app/core/search.service';

@Component({
  selector: 'app-column-selector-field-list',
  templateUrl: './column-selector-field-list.component.html',
  styleUrls: ['./column-selector-field-list.component.scss'],
})
export class ColumnSelectorFieldListComponent  implements OnInit {
  title;
  fields: IField[];
  tableKey: string;
  activeColumns: string[];
  constructor(
    public nav: IonNav,
    public navParams: NavParams,
    public ss: SearchService,
  ) { }

  ngOnInit() {
    this.title = this.navParams.get('section').key;
    this.fields = this.navParams.get('section').value;
    this.tableKey = this.nav.rootParams.tableKey;
    this.activeColumns = this.nav.rootParams.activeColumns;

    console.log('column-selector-field-list params', this.activeColumns, this.nav.rootParams);
  }

  toggleField(field: IField) {
    console.log('toggling field', field);
    const columns = this.activeColumns;
    // const columns = this.ss.userSettings$.getValue()[this.nav.rootParams.tableKey];
    const idx = columns.indexOf(field.field);
    if (idx > -1) {
      columns.splice(idx, 1);
    } else {
      columns.push(field.field);
    }
    this.ss.fieldDispatcher$.next({
      tableKey: this.tableKey,
      fields: columns
    });
    // this.ss.updateSetting(this.nav.rootParams.tableKey, columns);
  }

  dismiss() {

  }

}
