import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { CountryService } from '@app/core/country.service';
import { ClarityModule } from '@clr/angular';

@Component({
  standalone: true,
  imports: [CommonModule, ClarityModule],
  selector: 'app-flag-icon',
  templateUrl: './flag-icon.component.html',
  styleUrls: ['./flag-icon.component.scss'],
})
export class FlagIconComponent  implements OnInit {
  @Input() iso2: string;
  @Input() showLabel = false;
  countryName: string;
  constructor(
    public cs: CountryService
  ) { }

  ngOnInit() {
    this.countryName = this.cs.getCountryName(this.iso2);
  }

}
