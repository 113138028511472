import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clr-sc-table3-modal',
  templateUrl: './clr-sc-table3-modal.component.html',
  styleUrls: ['./clr-sc-table3-modal.component.scss'],
})
export class ClrScTable3ModalComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
