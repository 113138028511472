<ion-app>
  <ng-container *ngIf="router.url.split('/')[1] !== 'public'">
    <ion-header [translucent]="true" *ngLet="app?.darkMode$ | async as darkMode;">
      <ion-toolbar class="header-toolbar" *ngLet="ss.currentSearch$ | async as currentSearch">
        <ion-progress-bar type="indeterminate" *ngIf="navbar.showLoader"></ion-progress-bar>
        <ion-buttons *ngIf="isLoggedIn" slot="start">
          <ion-menu-button menu="navigation-menu" (click)="openNavMenu()" slot="start" auto-hide="false"></ion-menu-button>
        </ion-buttons>
        <ion-title slot="start" class="navbar-title-container">
          <ng-container *ngIf="app?.mobileView">
            <img [src]="'assets/chn_icon_full.png'" [ngClass]="['logo', darkMode ? 'darkMode' : 'lightMode']" [routerLink]="['/home']">  
          </ng-container>
          <ng-container *ngIf="!app?.mobileView">
            <ng-container [ngSwitch]="router.url.split('/')[1]">
              <img *ngSwitchCase="'playground'" [src]="'assets/chn_playground_logo.png'" [ngClass]="['logo', darkMode ? 'darkMode' : 'lightMode']" [routerLink]="['/home']">
              <img *ngSwitchCase="'explorer'" [src]="'assets/chn_explorer_logo.png'" [ngClass]="['logo', darkMode ? 'darkMode' : 'lightMode']" [routerLink]="['/home']">
              <img *ngSwitchCase="'scout'" [src]="'assets/chn_scout_logo.png'" [ngClass]="['logo', darkMode ? 'darkMode' : 'lightMode']" [routerLink]="['/home']">
              <img *ngSwitchDefault [src]="'assets/chn_logo.png'" [ngClass]="['logo', darkMode ? 'darkMode' : 'lightMode']" [routerLink]="['/home']">
            </ng-container>
          </ng-container>
          <div *ngIf="!app.mobileView && !router.url.includes('org-search') && !router.url.includes('playground')"  class="navbar-search-container">
            <div class="navbar-current-search-container">
              <ion-item button class="current-search-item">
                <ion-buttons slot="start">
                  <ion-button (click)="onOpenSearchMenuModal()">
                    <ion-icon slot="icon-only" name="apps"></ion-icon>
                  </ion-button>
                </ion-buttons>
                <ion-select 
                  #searchSelect
                  [interface]="app?.mobileView ? 'action-sheet' : 'popover'"
                  [interfaceOptions]="searchMenuOptions"
                  [ngModel]="currentSearch ? currentSearch : {name: 'All Technologies', uid: null}"
                  (ionChange)="onSelectUserSearch($event?.detail?.value)"
                  label="Focus Area:"
                  class="never-flip"
                  placeholder="All Technologies"
                  [compareWith]="searchCompareFn"
                  aria-label="Focus Area" 
                  justify="start"
                >
                  <ion-select-option *ngIf="!router.url.includes('explorer')" [value]="{'uid': null, 'name': 'All Technologies'}">
                    All Technologies
                  </ion-select-option>
                  <ion-select-option *ngFor="let s of ss.searches?.userSearches.slice(0, 4)" [value]="s">
                    {{s.name | titlecase}}
                  </ion-select-option>
                  <ion-select-option *ngIf="ss.searches?.userSearches?.length > 4" [value]="{'uid': 'view_all'}">
                    View All Searches
                  </ion-select-option>
                </ion-select>
                <ion-buttons slot="end">
                  <ion-button *ngIf="currentSearch && !router.url.includes('explorer')" (click)="onClearCurrentSearch()">
                    <ion-icon slot="icon-only" class="clear-icon" name="close-outline"></ion-icon>
                  </ion-button>
  
                </ion-buttons>
              </ion-item>
            </div>
  
  
            <!-- <div *ngIf="!ss?.currentSearch" class="navbar-search-input-container">
              <ion-searchbar 
                class = "navbar-search-input"
                placeholder="Start a new search"
                [(ngModel)]="searchInput" 
                (search)="onSearch()" 
                (ngModelChange)="searchInput = $event"
                style = "padding-inline-end: 0;"
              >
              </ion-searchbar>
              <div class="navbar-search-suffix">
                <ion-icon class="search-icon" name="search-outline"></ion-icon>
              </div>
              <div class="navbar-search-suffix advanced" [matMenuTriggerFor]="searchMenu">
                <ion-icon class="advanced-search-icon" name="menu" name="caret-down-outline"></ion-icon>
                <mat-menu #searchMenu="matMenu">
                  <button mat-menu-item [matMenuTriggerFor]="savedSearches">
                    <mat-icon>saved_search</mat-icon>
                    <span>My Searches</span>
                  </button>
                  <button mat-menu-item [matMenuTriggerFor]="focusAreas">
                    <mat-icon>biotech</mat-icon>
                    <span>Focus Areas</span>
                  </button>
                </mat-menu>
                <mat-menu #savedSearches="matMenu">
                  <button mat-menu-item (click)="onSelectUserSearch(s)" *ngFor="let s of ss.searches?.userSearches">{{s.name}}</button>
                </mat-menu>
                <mat-menu #focusAreas="matMenu">
                  <button mat-menu-item (click)="onSelectUserSearch(s)" *ngFor="let s of ss.searches?.focusAreas">{{s.name}}</button>
                </mat-menu>
              </div>
            </div> -->
          </div>
        </ion-title>
        <ion-buttons slot="end" *ngIf="!isLoggedIn">
          <ion-button [routerLink]="['/auth/login']">Sign In</ion-button>
          <ion-button [routerLink]="['/auth/signup']">Sign Up</ion-button>
        </ion-buttons>
  
        <ion-buttons *ngIf="isLoggedIn" slot="end">
          <ion-button [matMenuTriggerFor]="notificationsMenu" >
            <ion-icon slot="icon-only" name="notifications-outline"></ion-icon>
          </ion-button>
          <mat-menu #notificationsMenu="matMenu">
            <button mat-menu-item>
              <span>0 Notifications</span>
            </button>
          </mat-menu>
          <ion-button [routerLink]="['/settings']">
            <ion-icon slot="icon-only" name="settings-outline"></ion-icon>
          </ion-button>
          <ion-menu-button menu="end" slot="end">
            <ion-icon name="person-circle-outline"></ion-icon>
          </ion-menu-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-menu *ngIf="isLoggedIn" side="start" contentId="main-content" menuId="navigation-menu">
      <ion-header>
        <ion-toolbar>
          <ion-title>Navigation</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-list>
          <ion-list-header>
            <ion-title>
              Recent
            </ion-title>
          </ion-list-header>
          <ion-item [routerLink]="['/home']">
            <ion-icon slot="start" name="home-outline"></ion-icon>
            <ion-label>Home</ion-label>
          </ion-item>
          <ion-item [routerLink]="['/settings']">
            <ion-icon slot="start" name="settings-outline"></ion-icon>
            <ion-label>Account</ion-label>
          </ion-item>
          <ion-item [routerLink]="['/playground']">
            <ion-icon slot="start" name="cube-outline"></ion-icon>
            <ion-label>Playground</ion-label>
          </ion-item>
          <ion-list-header>
            <ion-title>Products</ion-title>
          </ion-list-header>
          <ion-menu-toggle menu="start" auto-hide="true">
            <ng-container *ngFor="let product of cs.products">
              <ng-container *ngIf="product.category === 'primary'">
                <ion-item 
                  class="product-item"
                  button 
                  [routerLink]="[product.route]" 
                  [disabled]="product?.coming_soon || !cs.canAccessFeatures(product.features_required)" 
                >
                  <ion-icon *ngIf="product.icon" slot="start" [name]="product.icon"></ion-icon>
                  <ion-label class="ion-text-wrap">
                    <h3>{{product.name}}</h3>
                    <p>{{product.caption}}</p>
                  </ion-label>
                  <ion-icon name="alarm-outline" slot="end" *ngIf="product?.coming_soon" [matTooltip]="'Coming Soon'"></ion-icon>
                </ion-item>
              </ng-container>
            </ng-container>
          </ion-menu-toggle>
          <ion-accordion-group>
            <ion-accordion value="first">
              <ion-item slot="header">
                <ion-label>More Tools</ion-label>
              </ion-item>
              <div slot="content">
                <ion-menu-toggle menu="end" auto-hide="true">
                  <ion-list>
                    <ng-container *ngFor="let product of cs.products">
                      <ng-container *ngIf="product.category === 'add-on'">
                        <ion-item 
                          class="product-item"
                          button 
                          [routerLink]="[product.route]" 
                          [disabled]="!product.available || !cs.canAccessFeatures(product.features_required)" 
                          >
                          <ion-icon *ngIf="product.icon" slot="start" [name]="product.icon"></ion-icon>
                          <ion-label class="ion-text-wrap">
                            <h3>{{product.name}}</h3>
                            <p>{{product.caption}}</p>
                          </ion-label>
                          <ion-icon name="alarm-outline" slot="end" *ngIf="product?.coming_soon" [matTooltip]="'Coming Soon'"></ion-icon>
                        </ion-item>
                      </ng-container>
                    </ng-container>
                  </ion-list>
                </ion-menu-toggle>
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-menu *ngIf="isLoggedIn" side="end" contentId="main-content">
      <ion-content>
        <ion-accordion-group>
          <ion-accordion value="first">
            <ion-item slot="header">
              <ion-label>Focus Areas</ion-label>
            </ion-item>
            <div slot="content">
              <ion-menu-toggle menu="end" auto-hide="true">
                <ion-list>
                  <ion-item style="cursor: pointer;" *ngFor="let uq of demo?.userQueries$ | async" (click)="onSelectUserQuery(uq)">
                    <ion-labe>{{uq.name}}</ion-labe>
                  </ion-item>
                </ion-list>
              </ion-menu-toggle>
            </div>
          </ion-accordion>
        </ion-accordion-group>
      </ion-content>
      <ion-footer>
        <ion-item *ngIf="isAdmin" [routerLink]="['/admin']">
          <span>Admin</span>
        </ion-item>
        <ion-item *ngIf="isLoggedIn" (click)="signOut()">
          <ion-icon name="log-out-outline"></ion-icon>
          <span>Sign out</span>
        </ion-item>
        <ion-item  *ngIf="!isLoggedIn"  [routerLink]="['/auth']">
          <ion-icon name="log-in-outline"></ion-icon>
          <span>Sign in</span>
        </ion-item>
      </ion-footer>
    </ion-menu>
  </ng-container>

  <ion-content id="main-content">
    <ion-router-outlet></ion-router-outlet>
  </ion-content>

  <!-- <ion-router-outlet id="main-content"></ion-router-outlet> -->

</ion-app>
