<ion-header>
  <ion-toolbar>
    <ion-title>
      {{title}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button color="medium" (click)="onClose()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ng-container *ngIf="mode === 'cluster' || mode === 'supercluster'">
    <div class="summary-container">
      <ng-container *ngIf="summaryLoading">
        <ion-item *ngFor="let x of [1,2,3,4]">
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        </ion-item>
      </ng-container>
      <ion-item *ngIf="!summaryLoading && summary?.length">
        <ion-label class="ion-text-wrap">
          Cluster Summary
          <p class="description">
            {{summary}}
          </p>
        </ion-label>
      </ion-item>
    </div>
  
    <div class="applications-container">
      <ng-container *ngIf="applicationsLoading">
        <ion-item *ngFor="let x of [1,2,3,4]">
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        </ion-item>
      </ng-container>
      <ion-list *ngIf="!applicationsLoading && applications?.length">
        <ion-list-header>
          Applications
        </ion-list-header>
        <ion-item *ngFor="let application of applications">
          <ion-label class="ion-text-wrap">
            {{application?.name}}
            <p>{{application?.summary}}</p>
          </ion-label>
          <ion-buttons slot="end">
            <ion-chip color="primary" *ngFor="let c of application?.classifications">
              {{c}}
            </ion-chip>
          </ion-buttons>
        </ion-item>
      </ion-list>
    </div>
  </ng-container>
  <ng-container *ngIf="mode === 'document'">
    <div class="summary-container">
      <ng-container *ngIf="summaryLoading">
        <ion-item *ngFor="let x of [1,2,3,4]">
          <ion-skeleton-text [animated]="true"></ion-skeleton-text>
        </ion-item>
      </ng-container>
      <ng-container *ngIf="!summaryLoading && docSummary">
        <ion-item *ngIf="docSummary?.summary">
          <ion-label class="ion-text-wrap">
            <p class="description">
              {{docSummary?.summary}}
            </p>
          </ion-label>
        </ion-item>
        <ion-item class="ion-text-wrap st-item-container" *ngIf="docSummary?.areas_of_interest?.length">
          <div class="label">Areas of Interest</div>
          <div *ngFor="let s of docSummary?.areas_of_interest" class="list-item">{{s}}</div>
        </ion-item>
        <ion-item class="ion-text-wrap list-item-container" *ngIf="docSummary?.inventions?.length">
          <div class="label">Inventions</div>
          <div *ngFor="let s of docSummary?.inventions" class="list-item">{{s}}</div>
        </ion-item>
        <ion-list-header *ngIf="docSummary?.key_findings?.length">Key Points</ion-list-header>
        <ion-item class="ion-text-wrap" *ngFor="let s of docSummary?.key_findings">
          <div class="label">Key Point</div>
          <ion-text>{{s}}</ion-text>
        </ion-item>
        <ion-list-header *ngIf="docSummary?.applications?.length">Applications</ion-list-header>
        <ion-item class="ion-text-wrap" *ngFor="let s of docSummary?.applications">
          <div class="label">Application</div>
          <ion-text>{{s}}</ion-text>
        </ion-item>
      </ng-container>
    </div>
  </ng-container>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button (click)="onClickDocument()">View Full Document</ion-button>
  </ion-toolbar>
</ion-footer>