import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  showLoader = false;
  showSearchbar = false;
  showLayout = false;

  constructor() {
    
  }
}
