<div class="info-tooltip-container" [matTooltip]="text"  #tooltip="matTooltip"
  (click)="tooltip.toggle()"
  style="cursor: info;"
>
  <img 
  src="assets/info_icon.png" 
  class="info-tooltip-icon"
  
  >
</div>
<!-- <button class="info-tooltip-button" type="button" #tooltip="matTooltip" (click)="tooltip.toggle()" type="button" [matTooltip]="text"> -->
<!-- <button class="info-tooltip-button" [tooltip]="text" placement="top" delay="500" type="button">
  
  <img 
    src="assets/info_icon.png" 
    class="info-tooltip-icon"
  >
</button> -->
<!-- <clr-signpost style="height: 26px; width: 26px;">
  <clr-signpost-content>
    <h3 *ngIf="title">{{title}}</h3>
    <p>{{text}}</p>
  </clr-signpost-content>
</clr-signpost> -->