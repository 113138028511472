import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EsDocViewerComponent } from './es-doc-viewer.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { NgLetModule } from '@app/directives/ng-let/ng-let.module';
import { TableValuePipeModule } from '@app/pipes/table-value/table-value-pipe.module';
import { InfoTooltipComponent } from '../info-tooltip/info-tooltip.component';
import { ClarityIcons, angleIcon, infoCircleIcon } from '@cds/core/icon';
import { FlagIconComponent } from '../flag-icon/flag-icon.component';
import { TableValuePipe } from '@app/pipes/table-value/table-value.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatPaginatorModule } from '@angular/material/paginator';


ClarityIcons.addIcons(infoCircleIcon, angleIcon)
@NgModule({
  declarations: [
    EsDocViewerComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    FlagIconComponent,
    InfoTooltipComponent,
    NgLetModule,
    MatPaginatorModule,
    TableValuePipeModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [
    EsDocViewerComponent,
  ],
  providers: [
    TableValuePipe,
  ]
})
export class EsDocViewerModule { }
