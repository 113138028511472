import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableValuePipe } from './table-value.pipe';



@NgModule({
  declarations: [TableValuePipe],
  imports: [
    CommonModule
  ],
  exports: [TableValuePipe]
})
export class TableValuePipeModule { }
