<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title slot="start">{{title}}</ion-title>
    <!-- <ion-buttons slot="end">
      <ion-button [strong]="true" (click)="dismiss()">Confirm</ion-button>
    </ion-buttons> -->
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-item lines="none" *ngFor="let field of fields">
      <ion-checkbox labelPlacement="end" slot="start" [checked]="activeColumns.includes(field.field)" (ionChange)="toggleField(field)">
      </ion-checkbox>
      <ion-label>
        {{field.label}}
        <app-info-tooltip [key]="field.field"></app-info-tooltip>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>