<ion-header>
  <ion-toolbar>
    <ion-title>
      CHN 7-Day Free Trial
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-item>
    <ion-label class="ion-text-wrap">
      Sign Up to create your account and start the CHN 7-Day Full Access Free Trial.
    </ion-label>
  </ion-item>
  <ion-item>
    <ion-label class="ion-text-wrap">
      You do not need to enter any form of payment method to begin the trial. There is no risk and no commitment. You may cancel your trial at any time.
    </ion-label>
  </ion-item>
  <ion-list>
    <ion-list-header>
      Included Products:
    </ion-list-header>
    <ion-item>
      <ion-label>CHN Explorer</ion-label>
      <ion-text>Emerging Tech Search + Tech Scouting</ion-text>
    </ion-item>
    <ion-item>
      <ion-label>CHN Scout</ion-label>
      <ion-text>Competitive Intelligence, M&A, VC, Investment</ion-text>
    </ion-item>
    <ion-item>
      <ion-label>CHN Playground</ion-label>
      <ion-text>Early-Access versions of future products</ion-text>
    </ion-item>
  </ion-list>
  <!-- <ion-item>
    <ion-label class="ion-text-wrap">
      After 7 days, you will be charged $349/month on a recurring basis. You may cancel your trial or subscription at any time. If you cancel during the trial window, you will still have access for the remainder of the 7 day trial period.
    </ion-label>
  </ion-item> -->
</ion-content>
<ion-footer>
  <ion-item color="success" button (click)="close()" style="text-align: center !important;">
    <ion-label>GOT IT!</ion-label>
  </ion-item>
  <!-- <div class="buttons-container">
    <ion-button (click)="close()" color="success">Got it!</ion-button>
  </div> -->
</ion-footer>