import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BackendService } from '@app/core/backend.service';
import { IDemoCluster } from '@app/core/demo.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { AlgoliaMenuSelectComponent } from '../algolia-menu-select/algolia-menu-select.component';
import { IDocument } from '@app/models/app_models/document.model';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule],
  selector: 'app-ai-modal',
  templateUrl: './ai-modal.component.html',
  styleUrls: ['./ai-modal.component.scss'],
})
export class AiModalComponent  implements OnInit {
  mode: 'cluster' | 'supercluster' | 'document';
  cluster: IDemoCluster;
  document: IDocument;
  summaryLoading = true;
  applicationsLoading = true;

  summaryError = null;
  applicationsError = null;

  applications: {name: string, summary: string, type: string[]}[];
  summary: string =  null;

  docSummary: {
    summary: string;
    key_findings: string[];
    inventions: string[];
    areas_of_research: string[];
    doc_applications: string[];
  }

  title;

  constructor(
    private backend: BackendService,
    private modalController: ModalController,
  ) { }

  async ngOnInit() {
    console.log('ai modal init', this.mode, this.cluster);
    let cluster_ids = [];
    switch (this.mode) {
      case 'cluster':
        this.title = 'Summarize Cluster: ' + this.cluster.cluster_name;
        cluster_ids.push(this.cluster.cluster_id);
        await Promise.all([
          this.loadClusterSummary(cluster_ids),
          this.loadClusterApplications(cluster_ids),
        ])
        break;
      case 'supercluster':
        this.title = 'Summary Supercluster: ' + this.cluster.supercluster_name;
        cluster_ids = this.cluster.clusters.map(c => c.cluster_id);
        await Promise.all([
          this.loadClusterSummary(cluster_ids),
          this.loadClusterApplications(cluster_ids),
        ])
        break;
      case 'document':
        this.title = 'Document Summary';
        await this.loadDocumentSummary();
        break;
    }

  }

  async loadClusterSummary(cluster_ids: string[]) {
    try {
      const summary_res = await this.backend.post_route<{summary: string}>('ai/summarize_clusters', {
        cluster_ids
      });
      if (summary_res && summary_res.summary) {
        this.summary = summary_res.summary;
      }
    } catch (err) {
      console.error('Error getting summary', err);
      this.summaryError = 'Error getting AI Summary'
    } finally {
      this.summaryLoading = false;
    }
  }

  async loadClusterApplications(cluster_ids: string[]) {
    try {
      const app_res = await this.backend.post_route<{name: string, type: string[], summary: string}[]>('ai/get_cluster_applications', {
        cluster_ids
      });
      if (app_res) {
        this.applications = app_res;
      }
    } catch (err) {
      console.error('Error getting applications', err);
      this.applicationsError = 'Error getting applications';
    } finally {
      this.applicationsLoading = false;
    }
  }

  async loadDocumentSummary() {
    try {
      this.docSummary = await this.backend.post_route<any>('ai/summarize_doc', {
        title: this.document.title,
        abstract: this.document.abstract,
        orgs: this.document['orgs'].map(o => o.org),
        doc_origin: this.document.doc_origin,
      })
    } catch (err) {
      console.error('Error getting summary', err);
      this.summaryError = 'Error getting AI Summary'
    } finally {
      this.summaryLoading = false;
    }
  }

  onClose() {
    this.modalController.dismiss();
  }

  onClickDocument() {
    window.open(this.document.link, '_blank');
  }

}
