<ion-header>
  <ion-toolbar>
    <ion-title>Rename Search</ion-title>
    <ion-buttons slot="end">
      <ion-button color="medium" (click)="cancel()">CANCEL</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-item>
    <ion-input (keyup.ENTER)="onSubmit()" [clearInput]="true" placeholder="Name Your Search" label="Name:" labelPlacement="stacked" [(ngModel)]="newName"></ion-input>
  </ion-item>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()" color="danger">Cancel</ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button color="success" (click)="onSubmit()" [disabled]="!newName.length">Submit</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>