import { ComponentRef, Injectable } from '@angular/core';
import { RenameSearchModalComponent } from '@app/modules/rename-search-modal/rename-search-modal.component';
import { AiModalComponent } from '@app/modules/ai-modal/ai-modal.component';
import { IDocument } from '@app/models/app_models/document.model';
import { ClrScTable3ModalComponent } from '@app/modules/supercluster-table/clr-sc-table3-modal/clr-sc-table3-modal.component';
import { SearchMenuModalComponent } from '@app/modules/search-menu-modal/search-menu-modal.component';
import { ModalController } from '@ionic/angular';
import { IUserSearch } from '@app/models/app_models/search.model';
import { SearchService } from './search.service';
import { ICluster } from '@app/models/app_models/cluster.model';
import { EsDocViewer2Component } from '@app/modules/es-doc-viewer2/es-doc-viewer2.component';
import { FieldIsClickable } from '@app/models/app_models/fieldMap.model';
import { ICiOrg } from '@app/models/app_models/ci.model';
import { Router } from '@angular/router';
import { ColumnSelectorModalComponent } from '@app/modules/column-selector-modal/column-selector-modal.component';
import { UserSearchesModalComponent } from '@app/modules/user-searches-modal/user-searches-modal.component';
import { WelcomeModalComponent } from '@app/modules/welcome-modal/welcome-modal.component';
import { CookieModalComponent } from '@app/modules/cookie-modal/cookie-modal.component';
import { AuthService } from './auth.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { BackendService } from './backend.service';
import { ScoutService } from './scout.service';
import { PlaygroundInfoModalComponent } from '@app/modules/playground-info-modal/playground-info-modal.component';
import { FreeTrialDetailComponent } from '@app/modules/free-trial-detail/free-trial-detail.component';
import { SignupDetailsModalComponent } from '@app/signup-details-modal/signup-details-modal.component';


export interface IDynamicTableComponent {
  availableColumns: string[];
  refreshColumns: () => void;
}

export interface IDocViewerFilters {
  currentSearch?: IUserSearch;
  cluster_id?: string[];
  doc_origin?: string[];
  supercluster_name?: string[];
  cluster_name?: string[];
  org?: string[];
  org_type?: string[];
  country?: string[];
}
export interface IDocViewerParams {
  filters?: IDocViewerFilters;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  fieldIsClickable = FieldIsClickable;
  constructor(
    public modalController: ModalController,
    private ss: SearchService,
    private router: Router,
    private auth: AuthService,
    private cookieService: CookieService,
    private backend: BackendService,
    private scoutService: ScoutService,
  ) { }


  public async openDocModal(searchParams: IDocViewerParams) {
    console.log('opening doc modal', searchParams);
    const modal = await this.modalController.create({
      component: EsDocViewer2Component,
      componentProps: {
        mode: 'modal',
        searchParams
      }
    });
    await modal.present();
    await modal.onWillDismiss();
  }

  async openRenameSearchModal(search: IUserSearch) {
    const modal = await this.modalController.create({
      component: RenameSearchModalComponent,
      componentProps: {
        search
      }
    });
    await modal.present();
    await modal.onWillDismiss();
  }

  async openAiModal(mode: 'cluster' | 'supercluster' | 'document', data: {cluster?: ICluster, document?: IDocument}) {
    const modal = await this.modalController.create({
      component: AiModalComponent,
      componentProps: {
        mode: mode,
        cluster: data.cluster,
        document: data.document
      }
    });
    await modal.present();
  }

  async openClusterTableModal() {
    const modal = await this.modalController.create({
      component: ClrScTable3ModalComponent,
      componentProps: {
      }
    });
    await modal.present();
  }

  async openUserSearchesModal() {
    const modal = await this.modalController.create({
      component: UserSearchesModalComponent,
      componentProps: {
        mode: 'modal'
      }
    });
    await modal.present();
    return modal;
  }

  async openSearchMenuModal() {
    const modal = await this.modalController.create({
      component: SearchMenuModalComponent,
      componentProps: {
      }
    });
    await modal.present();
  }

  getDocOriginFromCell(cell: string) {
    let doc_origin = null;
    const prefixMap = {
      'doc_count_pub': 'pub',
      'doc_count_invention': 'invention',
      'usd_invention': 'invention',
      'doc_count_nsf_grant': 'nsf_grant',
      'usd_nsf_grant': 'nsf_grant',
      'doc_count_sbir_grant': 'sbir_grant',
      'usd_sbir_grant': 'sbir_grant',
      'doc_count_dod_grant': 'dod_grant',
      'usd_dod_grant': 'dod_grant',
      'doc_count_nih_grant': 'nih_grant',
      'usd_nih_grant': 'nih_grant',
      'grants_total': ['sbir_grant','dod_grant','nsf_grant','nih_grant'],
    }
    for (let [k, v] of Object.entries(prefixMap)) {
      if (cell.includes(k)) {
        doc_origin = v;
        break;
      }
    }
    return doc_origin;
  }

  async openColumnSelector(tableKey: string, availableColumns: string[], tableRef: IDynamicTableComponent) {
    console.log('opening column selector');
    const modal = await this.modalController.create({
      component: ColumnSelectorModalComponent,
      componentProps: {
        tableKey: tableKey,
        availableColumns: availableColumns,
        tableRef: tableRef
      }
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();
    console.log('column-selector-modal closed', data, role);

  }

  /**
   * Opens either the document viewer or the org profile depending on what was clicked
   * 
   * @param cell 'org' if you want to redirect to an org profile or any supported field
   * @param row the entire row of the cell that was clicked (in case we need to extract other fields for context)
   * @param moreFilters manually configured filters for the doc viewer
   * @param ignoreSearch if true, do not apply the current search when opening the document viewer
   * @returns null
   */
  clickCell(cell: string, row: ICluster | ICiOrg | any, moreFilters: IDocViewerFilters = {}, ignoreSearch = false) {
    console.log('clicked cell', cell, row);
    if (cell === 'org') {
      this.scoutService.onClickOrg(row.org_id)
      return;
    }
    if (!this.fieldIsClickable(cell) || !row[cell] || row[cell] === 0) {
      return;
    }
    let filters: IDocViewerFilters = {};
    // if the user clicked on a supercluster row, the cluster_name will be 'All'
    if (row.cluster_name && row.cluster_name !== 'All') {
      // the row is a cluster so just filter on cluster name
      filters.cluster_name = [row.cluster_name];
    } else if (row.supercluster_name) {
      // the row is a supercluster so filter on both the supercluster_name and the cluster_names
      filters.supercluster_name = [row.supercluster_name];
      if (row.clusters) {
        filters.cluster_name = row.clusters.map(c => c.cluster_name);
      }
    }
    if (!ignoreSearch && this.ss.currentSearch) {
      filters.currentSearch = this.ss.currentSearch;
    }
    if (row.org) {
      filters.org = [row.org]
    }
    if (!row.org && row.iso2) {
      filters.country = [row.iso2]
    }
    // extract the doc_origin from the column name
    const doc_origin = this.getDocOriginFromCell(cell);
    if (doc_origin) {
      filters.doc_origin = typeof(doc_origin) === 'string' ? [doc_origin] : doc_origin;
    }
    let searchParams: IDocViewerParams = {filters}
    this.openDocModal(searchParams) 
  }

  async openWelcomeModal() {
    const modal = await this.modalController.create({
      component: WelcomeModalComponent,
    });
    await modal.present();
    return modal;
  }

  async openCookieModal() {
    const modal = await this.modalController.create({
      component: CookieModalComponent,
    });
    await modal.present();
    return modal;
  }

  async openTrialDetailsModal() {
    const modal = await this.modalController.create({
      component: FreeTrialDetailComponent,
    });
    await modal.present();
    return modal;
  }

  async openSignupDetailsModal() {
    const modal = await this.modalController.create({
      component: SignupDetailsModalComponent,
    });
    await modal.present();
    return modal;
  }

  async openPlayground() {
    event.stopPropagation();
    console.log('redirecting to playground', navigator.cookieEnabled, this.cookieService.get('chnToken'));
    const settings = this.auth.currentUser.settings;
    // if (!this.auth.currentUser.settings.hasBeenPromptedForCookies) {
    //   const modal = await this.openCookieModal();
    //   const result = await modal.onDidDismiss();
    //   console.log('result', result);
    // }
    // try {
    //   console.log('setting cookie');
    //   const jwt = await this.backend.get_jwt() as string;
    //   this.cookieService.set('chnToken', jwt, {domain: 'chnexplorer.com'});
    //   console.log('set cookie');
    // } catch (err) {
    //   console.error('error setting cookie', err);
    // }
    // const playgroundUrl = {
    //   'local': 'http://localhost:8050',
    //   'dev': 'https://dev-playground.chnexplorer.com',
    //   'prod': 'https://playground.chnexplorer.com'
    // }[environment.build_env];
    // window.open(playgroundUrl, '_blank');
    this.router.navigate(['/playground'])
  }

  async openPlaygroundInfoModal() {
    event.stopPropagation();
    const modal = await this.modalController.create({
      component: PlaygroundInfoModalComponent,
    });
    await modal.present();
    return modal;
  }

}
