export const environment = {
  production: false,
  use_emulators: false,
  build_env: 'dev',
  // backendUrl: 'https://dev.api.chnscout.com:5000',
  backendUrl: 'https://core-eb-dev.chn-api.com',
  backendUrlLocal: 'http://localhost:5201',
  backendUrlDev: 'https://core-eb-dev.chn-api.com',
  backendUrlProd: 'https://core-eb-prod.chn-api.com',
  firebase: {
    apiKey: "AIzaSyCQ5SiJQ4Ga_x1TsnRguJnoYVVhGgmOBTI",
    authDomain: "chn-core-dev.firebaseapp.com",
    projectId: "chn-core-dev",
    storageBucket: "chn-core-dev.appspot.com",
    messagingSenderId: "377724064150",
    appId: "1:377724064150:web:93c6e6830b3b7bb7334db7",
    measurementId: "G-HJC8E50875"
  },
  stripe: {
    pub_key: 'pk_test_51MypgTARlNRIouG2TZFpptOsBKIy9i2b1HGbFtmyoVfSbfVn55nOo5Fb9FvivuuXQsQpdDzz9de18HQbO6v1TYo800T0MJpQuU',
  },
  typesense: {
    // host: 'dev.api.chnscout.com',
    // apiKey: 'u2CQJotQhlZhSTzHRJBxiT6m8baUeI70',
    // host: 'onprem.chnanalytics.com',
    // apiKey: 'faAuWSuS8u4QC7xI6sCsZ581uNzIXdDs',
    host: 'dev.api.chnscout.com',
    apiKey: 'u2CQJotQhlZhSTzHRJBxiT6m8baUeI70',
  },
};
