<ion-header>
  <ion-toolbar>
    <ion-title slot="start">Select Data Points</ion-title>
    <ion-buttons slot="end">
      <ion-button [strong]="true" (click)="dismiss()">Confirm</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-list-header>
      <ion-label>Add/Remove Fields</ion-label>
    </ion-list-header>
    <ion-item detail="true" (click)="onClickSection(section)" *ngFor="let section of columnGroupMap | keyvalue">
      <ion-label>{{section.key}}</ion-label>
    </ion-item>
  </ion-list>
  <ion-list>
    <ion-list-header>
      <ion-label>Reorder Selected Fields</ion-label>
    </ion-list-header>
    <div class="ion-padding" slot="content">
      <ion-reorder-group [disabled]="false" (ionItemReorder)="handleReorder($any($event))">
        <ion-item lines="none" *ngFor="let field of activeColumns">
          <ion-icon name="remove-outline" class="remove-icon" (click)="removeField(field)" slot="start"></ion-icon>
          <ion-label>
            {{getFieldLabel(field)}}
            <app-info-tooltip [key]="field"></app-info-tooltip>
          </ion-label>
          <ion-reorder slot="end"></ion-reorder>
        </ion-item>
      </ion-reorder-group>
    </div>
  </ion-list>
  <!-- <ion-accordion-group>
    <ion-accordion [value]="section.key" *ngFor="let section of columnGroupMap | keyvalue">
      <ion-item slot="header" color="light">
        <ion-label>{{section.key}}</ion-label>
      </ion-item>
      <div class="ion-padding" slot="content">
        <ion-item lines="none" *ngFor="let field of section.value">
          <ion-checkbox labelPlacement="end" slot="start" [checked]="columns.includes(field.field)" (ionChange)="toggleField(field)">
            {{field.label}}
            <app-info-tooltip [key]="field.field"></app-info-tooltip>
          </ion-checkbox>
        </ion-item>
      </div>
    </ion-accordion>
  </ion-accordion-group> -->
</ion-content>