import { Injectable } from '@angular/core';
import { ICiOrg } from '@app/models/app_models/ci.model';
import { BehaviorSubject, Subscription, debounceTime, distinctUntilChanged, distinctUntilKeyChanged, map, skipWhile, startWith } from 'rxjs';
import { BackendService } from './backend.service';
import { SearchService } from './search.service';
import { CustomerService } from './customer.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ScoutService {
  currentOrg$: BehaviorSubject<ICiOrg> = new BehaviorSubject(null);
  currentOrgSub: Subscription;
  orgQueryString = '';
  constructor(
    private backend: BackendService,
    private ss: SearchService,
    private cs: CustomerService,
    private router: Router,
  ) {

  }

  async setCurrentOrg(org_id: ICiOrg) {
    if (this.currentOrgSub) {
      this.currentOrgSub.unsubscribe();
    }
    this.currentOrgSub = this.ss.currentSearch$.pipe(
      startWith(null),
      debounceTime(300),
      map(s => {
        if (!s) { return null; }
        const {uid, cluster_selection, query_string, num_clusters} = s;
        return {uid, cluster_selection, query_string, num_clusters};
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a).split('').sort().join('') === JSON.stringify(b).split('').sort().join('')),
    ).subscribe(currentSearch => {
      this.backend.post_route<ICiOrg>('scout/get_org_summary', {org_id, search: currentSearch}).then(org => {
        console.log('setting current org', org);
        if (org && org.org_id) {
          this.currentOrg$.next({
            ...org,
            etl: Math.round((org.cluster_count_emerging_total / org.cluster_count_total) * 100)
          });
        } else {
          
        }
      })
    });
    this.currentOrg$.pipe(
      skipWhile(org => !org || !org.org_id),
      distinctUntilKeyChanged('org_id'),
    ).subscribe(org => {
      this.ss.onViewOrg(org);
    })
  }

  async clearCurrentOrg() {
    if (this.currentOrgSub) {
      this.currentOrgSub.unsubscribe()
    }
    this.currentOrg$.next(null);
  }

  async onClickOrg(org_id: string) {
    const canAccess = await this.cs.featureCheck('scout');
    if (canAccess) {
      let queryParams = null;
      if (this.ss.currentSearch && this.ss.currentSearch.uid) {
        queryParams = {search_id: this.ss.currentSearch.uid};
      }
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['/scout/' + org_id], {queryParams})
      );
      window.open(url, '_blank');
    }
  }

}
