import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { getApp, initializeApp } from 'firebase/app';
import { provideFirebaseApp } from '@angular/fire/app';
import { environment } from 'src/environments/environment';
import { provideAuth,getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { provideFirestore,getFirestore, connectFirestoreEmulator, enableMultiTabIndexedDbPersistence } from '@angular/fire/firestore';
import { provideFunctions,getFunctions, connectFunctionsEmulator } from '@angular/fire/functions';
import { provideStorage } from '@angular/fire/storage';
import { AppService } from './core/app.service';
import { HttpClientModule } from '@angular/common/http';
import { NgxEchartsModule } from 'ngx-echarts';
import { AuthService } from './core/auth.service';
import { EsDocViewerModule } from './modules/es-doc-viewer/es-doc-viewer.module';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NgxStripeModule } from 'ngx-stripe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { NgLetModule } from './directives/ng-let/ng-let.module';
import { getStorage } from 'firebase/storage';

let resolvePersistenceEnabled: (enabled: boolean) => void;
export const persistenceEnabled = new Promise<boolean>(resolve => {
  resolvePersistenceEnabled = resolve;
});
// const USE_EMULATORS = environment.use_emulators;
const USE_EMULATORS = false;
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(), 
    AppRoutingModule,
    EsDocViewerModule,
    FormsModule,
    MatIconModule,
    MatTooltipModule,
    ReactiveFormsModule,
    NgLetModule,
    MatMenuModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    NgxStripeModule.forRoot(environment.stripe.pub_key),
    HttpClientModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)), 
    provideAuth(() => {
      const auth = getAuth();
      if (USE_EMULATORS) {
        console.log('using auth emulator');
        connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true })
      }
      return auth
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (USE_EMULATORS) {
        console.log('using firestore emulator');
        connectFirestoreEmulator(firestore, 'localhost', 8080);
        enableMultiTabIndexedDbPersistence(firestore).then(
          () => resolvePersistenceEnabled(true),
          () => resolvePersistenceEnabled(false)
        )
      }
      return firestore
    }),
    provideStorage(() => getStorage()),
    provideFunctions(() => {
      const functions = getFunctions();
      if (USE_EMULATORS) {
        console.log('using functions emulator');
        connectFunctionsEmulator(functions, '127.0.0.1', 5001)
      }
      return functions
    }),
  ],
  providers: [
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: (appService: AppService) => {
        return () => new Promise<void>(async (resolve, reject) => {
          console.log(environment.firebase.projectId)
          // set dark mode
          const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
          if (darkMode) {
            // document.body.classList.remove('dark');
            document.body.classList.toggle('dark', darkMode);
          }
          await appService.appInit();
          console.log('after app service init');
          resolve();
        });
      },
      multi: true,
      deps: [AppService, AuthService] // keep the RouterService in deps so that it will load on init
    },
    CookieService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
