import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './core/auth.service';
import { NavbarService } from './core/navbar.service';
import { DemoService } from './core/demo.service';
import { AppService } from './core/app.service';
import { BackendService } from './core/backend.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionSheetButton, IonSelect, MenuController } from '@ionic/angular';
import { CustomerService } from './core/customer.service';
import { ProductDetails } from './models/app_models/billing.model';
import { IUserSearch } from './models/app_models/search.model';
import { SearchService } from './core/search.service';
import { ModalService } from './core/modal.service';
import { skipWhile, take } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  // @ViewChild(‘swiper’) swiper: SwiperComponent;
  @ViewChild('searchSelect') searchSelect: IonSelect;
  searchInput: string;
  isLoggedIn = false;
  isAdmin = false;
  productDetails = ProductDetails;
  searchMenuActions: ActionSheetButton[]

  searchMenuOptions = {
    header: 'Recent Focus Areas',
    // subHeader: 'Select a Focus Area',
  };

  constructor(
    public auth: AuthService,
    public navbar: NavbarService,
    // public demo: DemoService,
    public ss: SearchService,
    public app: AppService,
    private backend: BackendService,
    public router: Router,
    private route: ActivatedRoute,
    private menuCtrl: MenuController,
    public cs: CustomerService,
    public ms: ModalService,
  ) {
    // register();
    // this.searchInput = this.demo.queryString;
  }

  ngOnInit() {
    this.route.queryParams.pipe(skipWhile(qps => !qps.emailId || !qps.emailContact)).subscribe(async qps => {
      if (qps.emailId && qps.emailContact) {
        console.log('HERE' + JSON.stringify(qps))
        try {
          const res = await this.backend.log_event({
            type: 'user_activity',
            action: 'user_entered_from_email',
            emailId: qps.emailId,
            email: qps.emailContact,
          });
        } catch (err) {

        } finally {
          this.router.navigate([], {queryParams: {...qps, emailContact: null, emailId: null}, queryParamsHandling: 'merge'})
        }
      }
    });
    // this.demo.search$.subscribe(async search => {
    //   if (search.query_string != this.searchInput) {
    //     this.searchInput = search.query_string
    //   }
    // });
    // this.backend.post_route<string[]>('search/focus_areas').then(res => {
    //   this.focus_areas = res;  
    // });
    console.log('products', this.cs.products);
    const adminEmails = ['alecdaling@chnanalytics.com','carahughes@chnanalytics.com','sfhughes@chnanalytics.com']
    this.auth.currentUser$.subscribe(cu => {
      this.isLoggedIn = cu.isAuthenticated;
      // console.log('*********', adminEmails.includes(cu.data.email))
      if (this.isLoggedIn && adminEmails.includes(cu.data.email)) {
        this.isAdmin = true;
      }
    });
    this.ss.currentSearch$.subscribe(search => {
      console.log('search sub fired', search);
      if (search) {
        this.searchMenuActions = [
          {
            text: 'Clear Search',
            icon: 'close-outline',
            role: 'desctructive',
            data: {
              action: 'clear-search'
            }
          },
          {
            text: 'Edit Name',
            icon: 'create-outline',
            data: {
              action: 'edit-name',
            }
          },
          {
            text: 'Start New Search',
            icon: 'add-outline',
            data: {
              action: 'new-search',
            }
          },
          {
            text: 'Modify Search',
            icon: 'options-outline',
            data: {
              action: 'modify-search'
            }
          },
          {
            text: 'Cancel',
            role: 'cancel',
            data: {
              action: 'modify-search'
            }
          },
      
        ]
      } else {
        this.searchMenuActions = [
          {
            text: 'Start New Search',
            icon: 'add-outline',
            data: {
              action: 'new-search',
            }
          },
          {
            text: 'Cancel',
            role: 'cancel',
            data: {
              action: 'modify-search'
            }
          },
      
        ]
      }
    })
  }

  clearCurrentSearch() {
    this.searchInput = '';
  }

  openNavMenu() {
    this.menuCtrl.enable(true, 'navigation-menu');
    this.menuCtrl.open('navigation-menu');
  }

  signOut() {
    this.auth.signout();
  }

  async onSearch() {
    if (!this.searchInput || !this.searchInput.length) {
      return;
    }
    await this.ss.startNewSearch(this.searchInput);
    if (!this.router.url.includes('home')) {
      this.router.navigate(['/explorer', this.ss.currentSearch.uid]);
    }
  }

  async onSelectUserSearch(s: Partial<IUserSearch>) {
    console.log('selected user search', s);
    if (!s.uid || s.uid === 'clear') {
      await this.ss.clearCurrentSearch();
    } else if (s.uid === 'view_all') {
      const modal = await this.ms.openUserSearchesModal();
      modal.onWillDismiss().then(res => {
        this.searchSelect.value = this.ss.currentSearch ? this.ss.currentSearch : {uid: null, name: 'All Technologies'};
      })
    } else if (this.router.url.includes('explorer')) {
      if (this.router.url.includes('/search')) {
        this.router.navigate(['/explorer', s.uid, 'search'])
      } else {
        await this.ss.loadSearch(s.uid);
      }
      // this.router.navigate(['/explorer', s.uid])
    } else if (this.router.url.includes('scout')) {
      this.router.navigate([], {
        queryParams: {
          search_id: s.uid,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      })
    }
  }

  viewAllSavedSearches() {
    
  }

  searchCompareFn(x: IUserSearch, y: IUserSearch) {
    return x.uid == y.uid;
  }

  onClearCurrentSearch() {
    event.stopPropagation();
    this.ss.clearCurrentSearch();
  }

  onOpenSearchMenuModal() {
    event.stopPropagation();
    this.ms.openSearchMenuModal()
  }

}
