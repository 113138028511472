import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { AuthService, ICurrentUser } from './auth.service';
import { ICiOrg } from '@app/models/app_models/ci.model';
import { BehaviorSubject, Observable, distinctUntilChanged, map, shareReplay, skipWhile, switchMap, take, tap } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { IRecentOrg, ISavedOrg, IUserSearch, TExplorerClusterMode } from '@app/models/app_models/search.model';
import { SelectionModel } from '@angular/cdk/collections';
import { ICluster } from '@app/models/app_models/cluster.model';
import { EsDocViewerComponent } from '@app/modules/es-doc-viewer/es-doc-viewer.component';
import { LoadingController, ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';


export interface IClusterData {records: ICluster[], minmax: any};
export interface IUserSearches {focusAreas: IUserSearch[], userSearches: IUserSearch[]};
@Injectable({
  providedIn: 'root'
})
export class SearchService {
  savedOrgs: ISavedOrg[] = [];
  recentOrgs: IRecentOrg[] = [];
  searches: IUserSearches = {userSearches: [], focusAreas: []}

  clustersLoading = false;
  clusterSelectionLoading = false;

  readonly currentSearch$: BehaviorSubject<IUserSearch> = new BehaviorSubject(null);
  get currentSearch() {
    return this.currentSearch$.getValue();
  }

  clusterSelection = new SelectionModel<string>(true, []);
  clusterData$: BehaviorSubject<IClusterData> = new BehaviorSubject({records: [], minmax: null});

  // This is an event emitter for communicating to tables from modals.
  fieldDispatcher$: BehaviorSubject<{tableKey: string, fields: string[]}> = new BehaviorSubject(null);
  // just a helper function to normalize an array of numbers
  normalize = (val: number, val_min: number, val_max: number, target_min: number, target_max: number) => ((val - val_min) / (val_max - val_min)) * (target_max - target_min) + target_min;


  constructor(
    private backend: BackendService,
    private auth: AuthService,
    private fs: FirestoreService,
    private loadingController: LoadingController,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.currentSearch$.pipe(
      map(search => {
        // map only to the properties that should trigger a requery
        console.log('search changed', search);
        const {uid, query_string, num_clusters, growth_type} = search || {};
        return {uid, query_string, num_clusters, growth_type};
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a).split('').sort().join('') === JSON.stringify(b).split('').sort().join('')),
      tap(() => this.clustersLoading = true),
      switchMap(search => this.backend.post_route<IClusterData>('explorer/supercluster_table', {search})),
      map(clusterData => {
        clusterData.records = clusterData.records.map(cluster => {
          return {
            ...cluster,
            selected: this.isSuperclusterSelected(cluster)
          }
        });
        this.clustersLoading = false;
        return clusterData;
      }),
      shareReplay(),
    )
    .subscribe(clusterData => {
      this.clusterData$.next(clusterData);
    });

    this.route.params.pipe(
      skipWhile(ps => !ps['search_id']),
      map(ps => ps['search_id'])
    ).subscribe(search_id => {
      alert('got search_id ' + search_id)
      this.loadSearch(search_id)
    })

    this.route.params.subscribe(pm => {
      console.log('*********PARMS IN SERVICE', pm);
    })

    /**
     * Every time the clusterSelection changes, update currentSearch and write
     * to the DB but don't necessarily rerun the search
     */
    this.clusterSelection.changed.subscribe(() => {
      this.clusterSelectionLoading = true;
      this.updateCurrentSearch({
        cluster_selection: this.clusterSelection.selected,
      });
    });
  }

  async appInit() {
    return new Promise( async (resolve, reject) => {
      this.auth.currentUser$.pipe(
        skipWhile(cu => !cu || !cu.isInitialized),
        map(cu => {
          if (cu && cu.isAuthenticated) {
            return cu.fbUser.uid;
          } else {
            return null;
          }
        }),
        distinctUntilChanged(),
      ).subscribe(async userId => {
        if (userId) {
          await Promise.all([
            this.loadRecentOrgs(userId),
            this.loadSavedOrgs(userId),
            this.loadUserSearches(userId),
          ]);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    })
  }

  async clearCurrentSearch() {
    this.clusterSelection.clear();
    this.currentSearch$.next(null);
    if (this.router.url.includes('scout')) {
      const params = await this.route.queryParams.pipe(take(1)).toPromise();
      if (params.search_id) {
        this.router.navigate([], {queryParams: {
          ...params,
          search_id: null,
        }, queryParamsHandling: 'merge', replaceUrl: true})
      }
    }
  }

  async updateNonCurrentSearch(search_id: string, update: Partial<IUserSearch>) {
    if (!update || !search_id) {
      return;
    }
    const res = await this.backend.post_route<IUserSearches | IUserSearch>('user/update_search', {
      search_id,
      update,
      is_current_search: false,
    });
    this.searches = res as IUserSearches;
  }

  async updateCurrentSearch(update: Partial<IUserSearch>) {
    if (!update) {
      return;
    }
    if (this.currentSearch && this.currentSearch.uid) {
      const res = await this.backend.post_route<IUserSearches | IUserSearch>('user/update_search', {
        search_id: this.currentSearch.uid,
        update,
        is_current_search: true,
      });
      this.currentSearch$.next(res as IUserSearch);
    } else {
      console.error('current search does not have uid, not sure what to do')
    }
    this.clusterSelectionLoading = false;
  }

  async setExplorerClusterMode(mode: TExplorerClusterMode) {
    if (this.currentSearch) {
      if (this.currentSearch.explorer_cluster_mode === mode) {
        await this.updateCurrentSearch({
          explorer_cluster_mode: 'hidden'
        })
      } else {
        await this.updateCurrentSearch({
          explorer_cluster_mode: mode
        })
      }
    }
  }

  // only to be used by explorer-report component
  async loadSearch(search_id: string) {
    if (!search_id) {
      return;
    }
    const search = await this.backend.post_route<IUserSearch>('user/load_search', {
      search_id,
    });
    if (search) {
      this.currentSearch$.next(search);
    }
    if (search.cluster_selection) {
      this.clusterSelection.setSelection(...search.cluster_selection)
    }
    console.log('after loading search', this.currentSearch, this.clusterSelection.selected);
    return this.currentSearch;
  }

  async setCurrentSearch(search_id: string) {
    const url = this.router.url;
    if (url.includes('explorer')) {
      if (url.includes('new-search')) {
        this.router.navigate(['/explorer', search_id, 'search']);
      } else {
        const url_suffix = this.router.url.split('/').at(-1).split('?')[0]
        this.router.navigate(['/explorer', search_id, url_suffix])
      }
    } else if (url.includes('scout')) {
      const params = await this.route.queryParams.pipe(take(1)).toPromise();
      if (params.search_id) {
        this.router.navigate([], {
          queryParams: {
            ...params,
            search_id: null,
          }, queryParamsHandling: 'merge', replaceUrl: true
        })
      }
    }
  }

  async startNewSearch(query_string: string): Promise<IUserSearch> {
    this.clusterSelection.clear();
    if (!query_string || !query_string.length) {
      return;
    }
    this.currentSearch$.next(await this.backend.post_route('user/create_new_search', {
      query_string
    }));
    return this.currentSearch;
  }

  async loadUserSearches(userId?: string) {
    if (!userId) {
      userId = this.auth.currentUser.fbUser.uid;
    }
    this.searches = await this.backend.post_route<IUserSearches> ('user/get_user_searches');
  }

  async deleteSearch(search_id: string) {
    console.log('deleting search', search_id);
    if (!search_id) {
      return;
    }
    const loader = await this.loadingController.create({
      message: 'Deleting Search'
    });
    await loader.present();
    try {
      this.searches = await this.backend.post_route<IUserSearches>('user/delete_search', {search_id});
      if (this.router.url.includes('explorer')) {
        this.router.navigate(['/explorer','new-search'])
      }
    } catch (err) {
      console.error('Error deleting search', err);
    } finally {
      await loader.dismiss();
    }

  }


  /**
   * Clears the current search's temporary filters.
   * Right now, this only includes effects the clusterSelection and is only invoked by the cluster table.
   * This may change in the future so be careful.
   */
  async clearFilters() {
    this.clusterSelection.clear();
  }

  toggleCluster(cluster: ICluster, e) {
    event.preventDefault();
    console.log('toggling cluster', cluster, e);
    this.clusterSelection.toggle(cluster.cluster_id);
  }

  toggleSupercluster(supercluster: ICluster) {
    const isSelected = this.isSuperclusterSelected(supercluster);
    if (isSelected) {
      this.clusterSelection.setSelection(
        ...this.clusterSelection.selected.filter(c => !c.includes(supercluster.supercluster_id))
      )
    } else {
      const cluster_ids = (supercluster.clusters as ICluster[]).map(c => c.cluster_id);
      const newSelection = cluster_ids.concat(this.clusterSelection.selected);
      this.clusterSelection.setSelection(...newSelection)
    }
    // console.log('after toggling cluster', this.demo.clusterSelection.selected)
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isSuperclusterSelected(supercluster: ICluster) {
    const currentSelection = this.clusterSelection.selected;
    let isSelected = false;
    for (let cluster_id of currentSelection) {
      if (cluster_id.includes(supercluster.supercluster_id)) {
        isSelected = true;
      }
    }
    return isSelected;
  }

  /**
   * 
   * SAVED ORGS STUFF
   * 
   */
  async loadSavedOrgs(userId?: string) {
    if (!userId) {
      userId = this.auth.currentUser.fbUser.uid;
    }
    this.savedOrgs = await this.backend.post_route('user/get_saved_orgs');
  }

  async loadRecentOrgs(userId?: string) {
    if (!userId) {
      userId = this.auth.currentUser.fbUser.uid;
    }
    this.recentOrgs = await this.backend.post_route('user/get_recent_orgs');
  }

  async onViewOrg(org: ICiOrg) {
    try {
      this.recentOrgs = await this.backend.post_route('user/on_view_org', {
        org_id: org.org_id,
        org: org.org,
        image: org.image,
      });
    } catch (err) {
      console.log('error in onViewOrg');
    }
  }

  async deleteSavedOrg(org_id: string) {
    if (!org_id) {
      return;
    }
    const loader = await this.loadingController.create({
      message: 'Deleting Saved Org'
    });
    await loader.present();
    try {
      this.savedOrgs = await this.backend.post_route('user/delete_saved_org', {
        org_id,
      });
    } catch (err) {
      console.log('error in deleteSavedOrg');
    } finally {
      await loader.dismiss();
    }
  }

  async toggleSavedOrg(org: ICiOrg | ISavedOrg) {
    console.log('toggling org', org);
    if (!org || !org.org_id) {
      return;
    }
    try {
      this.savedOrgs = await this.backend.post_route('user/toggle_saved_org', {
        org_id: org.org_id,
        org: org.org,
        image: org.image,
      });
    } catch (err) {
      console.log('error in toggleSavedOrg');
    } finally {
    }
  }

  async orgIsFavorited(org_id: string) {
    return this.savedOrgs.map(o => o.org_id).includes(org_id);
  }

}
