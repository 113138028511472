import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalService } from '@app/core/modal.service';
import { IonicModule, ModalController } from '@ionic/angular';

@Component({
  standalone: true,
  imports: [IonicModule, CommonModule],
  selector: 'app-free-trial-detail',
  templateUrl: './free-trial-detail.component.html',
  styleUrls: ['./free-trial-detail.component.scss'],
})
export class FreeTrialDetailComponent  implements OnInit {

  constructor(
    private modalController: ModalController,
    private ms: ModalService,
  ) { }

  ngOnInit() {}

  close() {
    this.modalController.dismiss();
  }

}
