import { IUser, IUserAccount, IUserSettings, User, UserAccount, UserSettings } from './user.model';
import { TCollection, THUMBNAIL_SIZE, AVATAR_SIZE, ToEntityOptions} from './common.model';
import { getDownloadURL, getStorage, ref } from '@firebase/storage';
import { avatarCollectionMap } from './entity.model';
// import { Dashboard, IDashboard } from './dashboard.model';

export type TIEntity = IUser | IUserAccount | IUserSettings;
export type TEntity = User | UserAccount | UserSettings;

export const fromDoc = <T extends TEntity>(doc: TIEntity, options: ToEntityOptions = {avatars: false, thumbs: false}) => {
  if (!doc) {
    return null;
  }
  const col = doc.collection;
  let ent: T;
  switch (col) {
    case 'users':
      ent = new User(doc as IUser) as T;
      break;
    case 'user_accounts':
      ent = new UserAccount(doc as IUserAccount) as T;
      break;
    case 'user_settings':
      ent = new UserSettings(doc as IUserSettings) as T;
      break;
    // case 'dashboards':
    //   ent = new Dashboard(doc as IDashboard) as T;
    //   break;
    default:
      throw new Error(`models.helpers.fromDoc() missing case for ${col}`);
  }
  if ((options.avatars || options.thumbs) && col in avatarCollectionMap) {
    getAvatar(ent, options.avatars, options.thumbs);
  }
  return ent;
};


export const getEntityDisplayType = (collection: TCollection) => {
  switch(collection) {
    case 'users':
      return 'user';
    case 'user_accounts':
      return 'user';
    case 'dashboards':
      return 'dashboard';
    case 'chats':
      return 'chat';
    default:
      console.error('Unsupported entity type', collection);
      return null;
  }
}

export const getEntityRoute = (collection: TCollection) => {
  switch(collection) {
    case 'users':
      return 'user';
    case 'user_accounts':
      return 'user';
    case 'dashboards':
      return 'dashboard'
    default:
      console.error('Unsupported entity type', collection);
      return null;
  }
}


export const getEntityType = (uid: string) => {
  const prefix = uid.slice(0, 3);
  switch (prefix) {
    case 'usr':
      return 'user';
    case 'dsh':
      return 'dashboard';
    case 'cht':
      return 'chat';
    default:
      console.error(`getEntityType not implemented for ${prefix}`);
      return null;
  }
}

export const getEntityCollection = (uid: string): TCollection => {
  const prefix = uid.slice(0, 3);
  switch (prefix) {
    case 'usr':
      return 'users';
    case 'dsh':
      return 'dashboards';
    case 'cht':
      return 'chats';
    default:
      console.error(`getEntityCollection not implemented for ${prefix}`);
      return null;
  }
}

export const collectionPrefixMap: {[k in TCollection]: string} = {
  users: 'usr',
  user_accounts: 'usr',
  user_settings: 'usr',
  user_searches: 'src',
  sessions: 'ses',
  dashboards: 'dsh',
  chats: 'cht',
  chat_messages: 'chm',
};

export const generateUid = (col: TCollection, fixedId: string = null) => {
  const makeid = () => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charsLength = characters.length;
    for ( let i = 0; i < 15; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charsLength));
    }
    return result;
  }
  if (!collectionPrefixMap[col]) {
    throw new Error(`generateUid not implemented for collection ${col}`);
  }
  const uid = `${collectionPrefixMap[col]}${fixedId ? fixedId : makeid()}`;
  return uid;
}

export const getAvatar = async (entity: TEntity, includeAvatar = false, includeThumbnail = true) => {
  const uid = entity.uid;
  const col = entity.collection;
  if (!includeAvatar && !includeThumbnail) {
    return;
  }
  const storage = getStorage();
  if (includeAvatar && col) {
    const path = `beta/${col}/${uid}/avatars/thumb@${AVATAR_SIZE}_${uid}.jpg`;
    const reference = ref(storage, path);
    try {
      entity.avatar = await getDownloadURL(reference);
    } catch (err) {
      entity.avatar = null;
    }
  }
  if (includeThumbnail && col) {
    const path = `beta/${col}/${uid}/avatars/thumb@${THUMBNAIL_SIZE}_${uid}.jpg`;
    const reference = ref(storage, path);
    try {
      entity.thumbnail = await getDownloadURL(reference);
      console.log('got thumb url', entity.thumbnail)
    } catch {
      entity.thumbnail = null;
    }
  }
}
