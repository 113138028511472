import { Injectable } from '@angular/core';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Stripe as stripe} from 'stripe';
import { Browser } from '@capacitor/browser';
import { BackendService } from './backend.service';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, skipWhile, switchMap, tap } from 'rxjs';
import { ICustomerProduct, IProductInfo, ProductDetails } from '@app/models/app_models/billing.model';
import { LoadingController, ModalController } from '@ionic/angular';
import { PayWallModalComponent } from '@app/modules/pay-wall-modal/pay-wall-modal.component';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public stripeCustomerId: string;
  
  private customer: stripe.Customer;
  private customerSubscriptions: stripe.Subscription[];
  public productsAndPrices: {product: stripe.Product, prices: stripe.Price[]}[];
  public products: IProductInfo[] = [];
  public customerProducts: ICustomerProduct[] = [];
  public refresher$: BehaviorSubject<any> = new BehaviorSubject(null);
  public features_enabled: string[];


  // pendingChanges: IPendingChange[] = [];

  constructor(
    private backend: BackendService,
    private auth: AuthService,
    private modalController: ModalController,
    private loadingController: LoadingController,
  ) { }

  async appInit() {
    // load in stripe info before app load so that paywall guards can resolve
    return new Promise((resolve, reject) => {
      combineLatest([
        this.refresher$,
        this.auth.currentUser$.pipe(
          skipWhile(cu => !cu || !cu.isInitialized),
          map(cu => {
            if (cu && cu.isAuthenticated) {
              return cu.account.stripeCustomerId;
            } else {
              return null;
            }
          }),
          distinctUntilChanged(),
        )
      ]).subscribe(async ([_, stripeCustomerId]) => {
        if (stripeCustomerId) {
          await this.loadCustomerAndProductData();
          resolve(this.customer);
        } else {
          resolve(null);
        }
      })
    })
  }

  async openPaywallModal(message: string) {
    const modal = await this.modalController.create({
      component: PayWallModalComponent,
      componentProps: {
        message,
      }
    });
    await modal.present();
    return modal;
  }

  public canAccessFeatures(features: string[] = []) {
    if (!features || !features.length) {return true;}
    for (let f of features) {
      if (!this.features_enabled.includes(f)) {
        return false;
      }
    }
    return true;
  }

  async featureCheck(feature: 'explorer' | 'scout') {
    if (!this.features_enabled.includes(feature)) {
      let message = '';
      switch (feature) {
        case 'explorer':
          message = 'The full version of CHN Explorer requires a paid subscription.'
          break;
        case 'scout':
          message = 'The full version of CHN Scout required a paid subscription.'
      }
      this.openPaywallModal(message);
      return false;
    } else {
      return true;
    }
  }

  async loadCustomerAndProductData() {
    await Promise.all([
      this.getCustomer(true),
      this.getCustomerSubscriptions(true),
      this.getProductsAndPrices(),
    ]);
    const customerProducts = [];
    let enabledFeatures = this.auth.currentUser.account.featuresEnabled;
    this.customerSubscriptions.forEach(sub => {
      let is_trial = false;
      let trial_start = null;
      let trial_end = null;
      if (sub.trial_start && sub.trial_end) {
        is_trial = true;
        trial_start = sub.trial_start;
        trial_end = sub.trial_end;
      }
      sub.items.data.forEach(item => {
        customerProducts.push({
          product_id: item.price.product as string, 
          price_id: item.price.id, 
          cancel_at: sub.cancel_at,
          cancel_at_period_end: sub.cancel_at_period_end,
          current_period_start: sub.current_period_start,
          current_period_end: sub.current_period_end,
          subscription_id: sub.id,
          subscription_item_id: item.id,
          interval: item.price.recurring.interval,
          is_trial,
          trial_start,
          trial_end
        } as ICustomerProduct);
      })
    });
    this.customerProducts = customerProducts;

    const products = [];
    for (let productAndPrices of this.productsAndPrices) {
      const {product, prices} = productAndPrices;
      if (ProductDetails[product.name] && product.active) {
        const productSubscription = this.getCustomerProduct(product.id);
        const cancel_at_period_end = productSubscription && productSubscription.cancel_at_period_end;
        let cancel_at = productSubscription && productSubscription.cancel_at;
        // I'm not sure if cancel_at is set if cancel_at_period_end == True so make sure it is set
        if (cancel_at_period_end && !cancel_at) {
          cancel_at = productSubscription.current_period_end
        }
        products.push({
          name: product.name,
          category: ProductDetails[product.name].category,
          product_id: product.id,
          is_subscribed: !!productSubscription,
          is_cancelled: productSubscription && (productSubscription.cancel_at || productSubscription.cancel_at_period_end) as boolean,
          current_period_end: !!productSubscription ? productSubscription.current_period_end : null,
          cancel_at,
          cancel_at_period_end,
          is_trial: productSubscription ? productSubscription.is_trial : null,
          trial_start: productSubscription ? productSubscription.trial_start : null,
          trial_end: productSubscription ? productSubscription.trial_end : null,
          prices: prices.map(price => ({
            price_id: price.id,
            is_subscribed: productSubscription && productSubscription.price_id === price.id,
            active: price.active,
            unit_amount: price.unit_amount,
            interval: price.recurring.interval
          })),
          ...ProductDetails[product.name]
        });
      }
    }
    this.products = products;
    for (let p of this.products) {
      if (p.is_subscribed) {
        enabledFeatures.push(...p.features_enabled);
      }
    }
    this.features_enabled = enabledFeatures;
  }

  productIsAuthorized(product_id: string): boolean {
    return this.customerProducts
      .map(product => product.product_id)
      .includes(product_id)
  }

  priceIsAuthorized(price_id: string): boolean {
    for (let product of this.customerProducts) {
      if (product.price_id === price_id) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns summary info on the customer's subscription to a particular product if it exists,
   * or null if the customer is not subscribed to the product
   * @param product_id
   */
  getCustomerProduct(product_id: string) {
    for (let product of this.customerProducts) {
      if (product.product_id === product_id) {
        return product;
      }
    }
    return null;
  }

  async getCustomer(refresh=false) {
    if (!this.customer || refresh) {
      this.customer = await this.backend.post_route<stripe.Customer>('stripe/get_customer');
    }
    return this.customer;
  }

  async getCustomerSubscriptions(refresh=false) {
    if (!this.customerSubscriptions || refresh) {
      this.customerSubscriptions = await this.backend.post_route<stripe.Subscription[]>('stripe/list_customer_subscriptions');
    }
    return this.customerSubscriptions;
  }

  async startCheckout(line_items: {price: string, quantity: number}[]) {
    const checkoutSession = await this.backend.post_route<stripe.Checkout.Session>('stripe/create_checkout_session', {line_items});
    const Stripe = await loadStripe(environment.stripe.pub_key);
    Stripe.redirectToCheckout({
      sessionId: checkoutSession.id
    })
  }

  // async startFreeTrial() {
  //   const trialLoader = await this.loadingController.create({
  //     message: 'Activating Free Trial'
  //   });
  //   await trialLoader.present();
  //   try {
  //     const res = await this.backend.post_route<stripe.Subscription>('stripe/create_trial_subscription');
  //     console.log('trial res', res);
  //   } catch (err) {
  //     console.error('something went wrong creating checkout session', err);
  //   } finally {
  //     await trialLoader.dismiss();
  //   }
  // }


  async startFreeTrial() {
    // check that the user has not already started a free trial
    if (this.auth.currentUser.account.hasStartedTrial) {
      alert('You have already activated this free trial');
      return;
    }
    let hasStartedTrial = false;
    for (let p of this.customerProducts) {
      if (p.is_trial) {
        hasStartedTrial = true;
        break;
      }
    }
    if (hasStartedTrial) {
      return;
    }
    const loader = await this.loadingController.create({
      message: 'Redirecting you to Stripe to start your Trial',
    });
    loader.present();
    // TODO: make sure no product_id or price_id is duplicated
    try {
      await this.startTrialCheckout();
    } catch (err) {
      console.error('something went wrong creating checkout session', err);
    }
    await loader.dismiss();
  }

  async startTrialCheckout() {
    const checkoutSession = await this.backend.post_route<stripe.Checkout.Session>('stripe/create_trial_checkout_session');
    const Stripe = await loadStripe(environment.stripe.pub_key);
    Stripe.redirectToCheckout({
      sessionId: checkoutSession.id
    })
  }

  async openCustomerPortal() {
    const portalSession = await this.backend.post_route<stripe.Checkout.Session>('stripe/create_customer_portal_session', {
      env: environment['build_env']
    });
    await Browser.open({url: portalSession.url});
  }

  async getProductsAndPrices() {
    if (!this.productsAndPrices) {
      const productsAndPrices = await this.backend.post_route<{products: stripe.Product[], prices: stripe.Price[]}>('stripe/list_products');
      this.productsAndPrices = productsAndPrices.products
      .map(product => {
        return {
          product,
          prices: productsAndPrices.prices.filter(price => price.product === product.id).sort((a, b) => a.unit_amount - b.unit_amount)
        }
      }).sort((a, b) => a.product.created - b.product.created)
    }
    return this.productsAndPrices;
  }

}
