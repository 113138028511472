import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { GetFieldDescription, GetFieldLabel } from '@app/models/app_models/fieldMap.model';
import { TooltipDirective } from '@app/directives/tooltip.directive';
import { ClarityModule } from '@clr/angular';
@Component({
  standalone: true,
  imports: [MatTooltipModule, MatButtonModule, MatIconModule, TooltipDirective, ClarityModule],
  selector: 'app-info-tooltip',
  templateUrl: './info-tooltip.component.html',
  styleUrls: ['./info-tooltip.component.scss'],
})
export class InfoTooltipComponent  implements OnInit {
  @Input() text: string = '';
  @Input() title: string = '';
  @Input() key: string;
  matTooltip = MatTooltip;
  constructor() { }

  ngOnInit() {
    if (this.key) {
      this.title = GetFieldLabel(this.key);
      this.text = GetFieldDescription(this.key);
    }
  }

}
