
export type TProductName = 'Explorer' | 'Scout' | 'Free' | 'Innnovators';
import { Stripe as stripe} from 'stripe';
export type TProductCategory = 'primary' | 'add-on' | 'bundle';
export type TFeature = 'explorer' | 'scout';


export interface IProductInfo extends IProductDetails {
  name: string;
  category: TProductCategory;
  product_id: string;
  is_subscribed: boolean;
  is_cancelled: boolean;
  cancel_at: number;
  cancel_at_period_end: boolean;
  current_period_end: number;
  is_trial: boolean;
  trial_start?: number;
  trial_end?: number;
  prices: {
    price_id: string;
    is_subscribed: boolean;
    active: boolean;
    unit_amount: number;
    interval: stripe.Price.Recurring.Interval
  }[];
};

export interface ICustomerProduct {
  product_id: string;
  price_id: string;
  cancel_at: number;
  cancel_at_period_end: boolean;
  current_period_start: number;
  current_period_end: number;
  subscription_id: string;
  subscription_item_id: string;
  interval: stripe.Price.Recurring.Interval;
  is_trial: boolean;
  trial_start?: number;
  trial_end?: number;
}

export interface IProductDetails {
  category: TProductCategory,
  features_enabled: string[];
  features_required: string[];
  coming_soon?: boolean;
  hasPlayground?: boolean;
  available: boolean;
  route?: string;
  use_cases?: string[];
  icon?: string;
  caption?: string;
  description?: string[];
  features?: string[];
  image?: string;
  text?: string;
}

export const ProductDetails: {[name: string]: IProductDetails} = {
  'Explorer + Scout': {
    category: 'bundle',
    available: true,
    features_enabled: ['explorer','scout'],
    features_required: [],
    route: '/explorer',
    text: 'CHN Explorer + Scout Bundle delivers seamless integration between Explorer and Scout, offering greater efficiencies for all of your tech scouting and competitive intelligence needs.',
    use_cases: ['M&A','VC','Investment','Strategy','Competitive Intelligence','Academic Research'],
    caption: 'Uncover the future of innovation',
    description: [
      'All the features of Scout + Explorer',
      'Seamless integration of AI Vectorsearch and Org Search/Analysis'
    ],
    features: [
      'AI Vectorsearch',
      'Org/Competitor Search',
    ]
  },
  Explorer: {
    category: 'primary',
    available: false,
    features_enabled: ['explorer'],
    features_required: ['explorer'],
    coming_soon: false,
    text: 'CHN Explorer identifies emerging technologies and the landscape that surrounds them including analysis of the key drivers of these technologies to include countries, organizations (academic, commercial, government research labs, etc.) and the subject matter experts involved with these technologies.',
    route: '/explorer',
    icon: 'locate-outline',
    image: 'assets/chn_explorer_logo.png',
    use_cases: ['M&A','VC','Investment','Strategy','Competitive Intelligence','Academic Research'],
    caption: 'Technology Scouting',
    description: [
      'Explore the current and future landscape of technological and economic development.',
      'Using AI-Powered Vectorsearch, you can find and analyze emerging technologies from over 200,000 technology clusters.'
    ],
    features: [
      'State of the Art AI and language processing.',
      'Data & Analysis from over 100,000,000 patents, publications, and research grants.',
      'AI-Powered Vectorsearch that makes searching faster, easier, and more productive.',
      'Our Country, Organization, and SME analysis lets you discover the key drivers of innovation.',
    ]
  },
  Scout: {
    available: false,
    route: '/scout',
    coming_soon: false,
    features_enabled: ['scout'],
    features_required: ['scout'],
    text: 'CHN Scout identifies the emerging technology composition of a competitor’s IP portfolio.  The tool scores individual company portfolios by how much emerging technology a company is invested in and then compares them against organizations (large commercial, small commercial & academics) that are highly similar in their IP composition.  We identify this number as a company’s Emerging Technology Leadership (ETL) score and believe it is a good proxy for how well a company is positioned to compete in the future.',
    icon: 'navigate-circle-outline',
    caption: 'Competitive Intelligence',
    category: 'primary',
    image: 'assets/chn_scout_logo.png',
    description: [
      'Find your real competition faster using our innovative AI-Powered Competitor Search',
      'Conduct a comprehensive analysis of any organization\'s technology portfolio in minutes',
    ],
    features: [
      'Search from over 8 Million Organizations worldwide.',
      'Examine any organization\'s entire technology cluster portfolio.',
      'Find related organizations, collaborators, and competitors for any organization in our dataset.'
    ]
  },
  Innovators: {
    available: false,
    coming_soon: true,
    caption: 'Talent Scouting',
    features_enabled: [],
    features_required: [],
    route: '/innovators',
    hasPlayground: true,
    text: 'CHN Innovators identifies the leading subject matter experts (SMEs) associated with any technology of interest to a user.  SMEs are ranked based on their H-Index (a measure of research impact) or other volume/quality metrics within specific technology areas. ',
    category: 'primary',
    image: 'assets/chn_innovators_logo.png',
    description: [
      'Find SMEs, inventors, thought leaders, and key innovators.',
    ],
    // features: [
    //   'Feature 1',
    //   'Feature 2 Feature 2 Feature 2 Feature 2',
    //   'Feature 3 Feature 3 Feature 3 Feature 3 Feature 3 Feature 3 Feature 3 Feature 3 Feature 3 Feature 3',
    //   'Feature 4'
    // ]
  },
  'Advanced Competitor Search': {
    available: false,
    coming_soon: true,
    category: 'add-on',
    features_enabled: [],
    features_required: [],
    // caption: 'Find Competitors',
    description: [
      'Search for competitors and related businesses/organizations faster and smarter than ever.',
      'Powered by our novel AI-powered Competitor Vectorsearch.'
    ]
  },
  'Tech Transfer': {
    available: false,
    category: 'add-on',
    coming_soon: true,
    hasPlayground: true,
    features_enabled: [],
    text: 'CHN Tech Transfer provides a user the ability to enter a patent and get back a list of organizations involved in highly similar work based on the “patent similarity” score between the submitted patent and the list of returned results.  By using this tool, any organization that has IP will be able to identify potential commercialization opportunities for their IP based on the IP similarity score. ',
    features_required: [],
    description: [
      'Use AI-Powered Patent Search to find commercialization and licensing opportunites.',
    ]
  },
  'Prior Art AI Search': {
    available: false,
    coming_soon: true,
    features_enabled: [],
    features_required: [],
    category: 'add-on',
    description: [
      'Employ state-of-the-art AI Language models to easily identify any prior art or overlap.',
    ]
  },
  'SBIR Search': {
    available: false,
    coming_soon: true,
    hasPlayground: true,
    features_enabled: [],
    features_required: [],
    text: 'CHN SBIR Search identifies the leading government-backed small businesses associated with any technology of interest to the user.  Small Business Innovation Research (SBIRs) grants are government-backed grants provided to small businesses (under 500 employees) who are engaged in advanced innovative R&D from a range of government agencies.',
    category: 'add-on',
    description: [
      'Find Government-Backed small businesses in any technological space.'
    ]
  },
  'Economic Development': {
    available: false,
    coming_soon: true,
    features_enabled: [],
    hasPlayground: true,
    features_required: [],
    text: 'CHN EDA provides an analysis of a region\'s composition of emerging technology and how they compare to other regions across the U.S.  This tool can identify what organizations are responsible for driving that activity both within their own region and in other regions across the U.S.',
    category: 'add-on',
    description: [
      'Powered by our novel AI-powered Competitor Vectorsearch.'
    ]
  }
}