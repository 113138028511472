import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { BackendService } from '@app/core/backend.service';
import { CountryService } from '@app/core/country.service';
import { IDocViewerFilters, IDocViewerParams, ModalService } from '@app/core/modal.service';
import { SearchService } from '@app/core/search.service';
import { GetFieldLabel } from '@app/models/app_models/fieldMap.model';
import { TableValuePipe } from '@app/pipes/table-value/table-value.pipe';
import { combineLatest, debounceTime, skipWhile, startWith } from 'rxjs';

@Component({
  selector: 'app-es-doc-viewer2',
  templateUrl: './es-doc-viewer2.component.html',
  styleUrls: ['./es-doc-viewer2.component.scss'],
  providers: [TableValuePipe],
})
export class EsDocViewer2Component implements OnInit, OnChanges {
  @Input() searchParams: IDocViewerParams = {filters: {}};
  @Input() mode: 'modal' | 'standalone' = 'standalone';
  @ViewChild('paginator') paginator: MatPaginator;
  localFilters: IDocViewerFilters = {};
  summaryLoading: boolean = false;
  summary: string = "";
  queryString = '';
  hits: any[];
  total = 0;
  pageIndex = 0;
  filterObjects = [
    {type: 'list', field: 'doc_origin', label: 'Doc Type'},
    {type: 'list', field: 'supercluster_name', label: 'Supercluster'},
    {type: 'list', field: 'cluster_name', label: 'Cluster'},
    {type: 'list', field: 'country', label: 'Country'},
    {type: 'list', field: 'org', label: 'Organization'},
    {type: 'list', field: 'org_type', label: 'Org Type'},
  ];
  filterOptions = {};
  docOriginLabels = {
    'dod_grant': 'DoD Grant',
    'sbir_grant': 'SBIR Grant',
    'nsf_grant': 'NSF Grant',
    'nih_grant': 'NIH Grant',
    'pub': 'Publication',
    'invention': 'Patent Family'
  };
  docsLoading = true;
  aggsLoading = true;
  
  sortOption = 'Year Desc.'
  sortOptions = ['Year Desc.', 'Year Asc.'];

  controls: {sort: string, from?: number, size?: number} = {
    'sort': this.sortOption
  };
  constructor(
    private backend: BackendService,
    public countryService: CountryService,
    private tvp: TableValuePipe,
    public ss: SearchService,
    public ms: ModalService,
  ) {
    this.filterObjects.forEach(o => {
      if (o.type === 'list' && !(this.searchParams.filters && this.searchParams.filters[o.field])) {
        if (!this.searchParams.filters) {
          this.searchParams.filters = {}
        }
        this.searchParams.filters[o.field] = [];
      }
    })
  }

  ngOnInit() {
    console.log('doc-viewer onInit params', this.searchParams, this.mode);
    this.query(true)
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('doc-viewer got changes', changes);
  }

  onChangeSort(e: CustomEvent) {
    if (e && e.detail && e.detail.value) {
      this.controls.sort = e.detail.value;
      this.query(false);
    }
  }

  async query(reset_page = true) {
    this.summary = '';
    this.docsLoading = true;
    this.aggsLoading = true;
    let searchParams = JSON.parse(JSON.stringify(this.searchParams || {}))

    this.backend.post_route('es/doc_search', {
      controls: this.controls,
      search: searchParams,
      queryString: this.queryString,
    }).then(res => {
      if (reset_page) {
        this.resetPage();
      }
      this.hits = res['hits'].map(h => {
        h.orgs.forEach(o => {
          o.iso2 = o.country;
          o.country = this.countryService.getCountryName(o.iso2);
        });
        return h;
      })
      this.total = res['total']
      this.docsLoading = false;
    });
    this.backend.post_route('es/filter_aggs', {
      search: searchParams,
      queryString: this.queryString,
    }).then(res => {
      console.log('res', res);
      this.formatFilterOptions(res);
      this.aggsLoading = false;
    })
  }

  onPage(e) {
    console.log('page', e);
    this.controls = {
      ...this.controls,
      from: e.pageIndex * e.pageSize,
      size: e.pageSize,
    }
    this.query(false);
  }

  resetPage() {
    this.pageIndex = 0;
    this.paginator.pageIndex = this.pageIndex;
  }

  onSearchInput(e) {
    this.queryString = e.detail.value;
    this.query();
  }

  toggleFilterValue(field: string, value: string) {
    if (!this.searchParams.filters[field]) {
      this.searchParams.filters[field] = [value];
    } else {
      const idx = this.searchParams.filters[field]
      if (this.searchParams.filters[field].includes(value)) {
        // remove
        this.searchParams.filters[field].splice(idx, 1);
      } else {
        this.searchParams.filters[field].push(value);
      }
    }
    this.query();
  }

  formatFilterOptions(aggs) {
    Object.entries(aggs).forEach(([field, bs]) => {
      const buckets = bs as {key: string, doc_count: number}[];
      switch (field) {
        case 'doc_origin':
          this.filterOptions[field] = buckets.map(b => ({
            key: b.key,
            label: this.docOriginLabels[b.key],
            doc_count: this.tvp.transform(b.doc_count, 'doc_count')
          }));
          break;
        default:
          this.filterOptions[field] = buckets.map(b => ({
            key: b.key,
            label: b.key,
            doc_count: this.tvp.transform(b.doc_count, 'doc_count')
          }));
          break;
      }
    })
  }


  clearAllFilters() {
    Object.keys(this.searchParams.filters).forEach(f => {
      this.searchParams.filters[f] = [];
    });
    this.query();
  }

  onClickDocument(doc) {
    window.open(doc.link, '_blank');
  }

  summarizeDocs() {
    this.summaryLoading = true
    let doc_titles = this.hits.map(hit => hit.title)
    console.log(doc_titles)
    this.backend.post_route('ai/summarize_docs', {
      titles: doc_titles
    }).then(res => {
      this.summary = res as string;
      this.summaryLoading = false
    })

  }
}
