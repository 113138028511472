import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalService } from '@app/core/modal.service';
import { IonicModule, ModalController } from '@ionic/angular';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule],
  selector: 'app-playground-info-modal',
  templateUrl: './playground-info-modal.component.html',
  styleUrls: ['./playground-info-modal.component.scss'],
})
export class PlaygroundInfoModalComponent  implements OnInit {

  constructor(
    private modalController: ModalController,
    private ms: ModalService,
  ) { }

  ngOnInit() {

  }

  close() {
    this.modalController.dismiss();
  }

  goToPlayground() {
    this.modalController.dismiss();
    this.ms.openPlayground();
  }

}
