import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@app/core/modal.service';
import { IonicModule, ModalController } from '@ionic/angular';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule],
  selector: 'app-pay-wall-modal',
  templateUrl: './pay-wall-modal.component.html',
  styleUrls: ['./pay-wall-modal.component.scss'],
})
export class PayWallModalComponent  implements OnInit {
  message: string;
  constructor(
    private modalController: ModalController,
    private router: Router,
    private ms: ModalService,
  ) { }

  ngOnInit() {

  }

  cancel() {
    this.modalController.dismiss();
  }

  goToProducts() {
    this.modalController.dismiss().then(() => {
      this.router.navigate(['/settings','billing'])
    })
  }

  openPlayground() {
    this.modalController.dismiss();
    this.ms.openPlayground();
  }

}
