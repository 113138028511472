import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { BackendService } from '@app/core/backend.service';
import { SearchService } from '@app/core/search.service';
import { IUserSearch } from '@app/models/app_models/search.model';
import { IonicModule, LoadingController, ModalController } from '@ionic/angular';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule],
  selector: 'app-rename-search-modal',
  templateUrl: './rename-search-modal.component.html',
  styleUrls: ['./rename-search-modal.component.scss'],
})
export class RenameSearchModalComponent  implements OnInit {
  search: IUserSearch;
  newName;
  loading = false;

  constructor(
    public ss: SearchService,
    private ionModal: ModalController,
    private backend: BackendService,
    private loadingController: LoadingController,
  ) { }

  ngOnInit() {
    this.newName = this.search.name;
  }

  cancel() {
    return this.ionModal.dismiss(null, 'cancel');
  }

  async onSubmit() {
    if (!this.newName || !this.newName.length) {
      return;
    }
    const loader = await this.loadingController.create({
      message: 'Updating Search Name',
    });
    await loader.present();
    try {
      if (this.ss.currentSearch.uid === this.search.uid) {
        await this.ss.updateCurrentSearch({name: this.newName});
      } else {
        await this.ss.updateNonCurrentSearch(this.search.uid, {name: this.newName})
      }
    } catch (err) {
      console.log('error updating search');
    } finally {
      await loader.dismiss();
      await this.ionModal.dismiss();
    }
  }

}
