<ion-header>
  <ion-toolbar>
    <ion-title>Welcome to CHN Explorer!</ion-title>
    <ion-buttons slot="end">
      <ion-button color="medium" (click)="cancel()">CLOSE</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-item *ngIf="isTrialMode">
    <ion-label class="ion-text-wrap">Thank you for starting your free trial!</ion-label>
  </ion-item>
  <ion-item>
    <ion-label color="success" class="ion-text-wrap">Your free trial .</ion-label>
    <ion-buttons slot="end">
      <ion-button fill="outline" color="success" (click)="modalService.openTrialDetailsModal()">Details</ion-button>
    </ion-buttons>
  </ion-item>
  <ion-item>
    <ion-label class="ion-text-wrap" color="primary">
      Get early access to future CHN products for FREE in the CHN Playground.
    </ion-label>
    <ion-buttons slot="end">
      <ion-button fill="outline" color="primary" (click)="modalService.openPlaygroundInfoModal()">Details</ion-button>
    </ion-buttons>
  </ion-item>
  <ion-item>
    Questions or comments? <a href = "mailto: support@chnanalytics.com">&nbsp;Send us an email</a>
  </ion-item>

  <!-- <ion-item>
    <ion-label class="ion-text-wrap">Or you can view sample datasets, trial our capabilities, and preview future products for FREE.</ion-label>
    <ion-buttons slot="end">
      <ion-button color="primary">View the CHN Playground</ion-button>
    </ion-buttons>
  </ion-item> -->
</ion-content>
<ion-footer>
  <ion-item color="primary" button (click)="goToPlayground()" style="text-align: center !important;">
    <ion-label style="font-weight: 500 !important;"  class="ion-text-wrap">VIEW THE PLAYGROUND</ion-label>
  </ion-item>
  <ion-item color="success" button (click)="goToCheckout()" style="text-align: center !important;">
    <ion-label style="font-weight: 500 !important;"  class="ion-text-wrap">START YOUR 7 DAY FREE TRIAL</ion-label>
  </ion-item>
</ion-footer>
<!-- <ion-footer>
  <ion-toolbar>
    <ion-button color="primary" (click)="onClickPlayground()">View Playground</ion-button>
    <ion-button  slot="end" color="success" (click)="cancel()">View Products</ion-button>
  </ion-toolbar>
</ion-footer> -->