<ion-split-pane when="md" contentId="doc-modal-content">
  <ion-menu  menuId="filter-menu" contentId="doc-modal-content">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button (click)="clearAllFilters()">Clear Filters</ion-button>
        </ion-buttons>
        <ion-title>Filters</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div class="filters-container">
        <ion-list>
          <ion-item>
            <!-- <app-sort-select [sortOptions]="sortOptions"></app-sort-select> -->
            <ion-select label="Sort" [(ngModel)]="sortOption" (ionChange)="onChangeSort($event)" interface="popover">
              <ion-select-option *ngFor="let so of sortOptions" [value]="so">{{so}}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-accordion-group>
            <ion-accordion *ngFor="let config of filterObjects" [value]="config.field">
              <ion-item slot="header">
                <ion-label>{{config.label}}</ion-label>
              </ion-item>
              <div slot="content">
                <ng-container *ngIf="config?.type === 'list'">
                  <ion-list>
                    <ng-container *ngFor="let option of filterOptions[config.field]">
                      <ion-checkbox 
                        labelPlacement="end" 
                        (ionChange)="toggleFilterValue(config.field, option.key)"
                        [checked]="searchParams['filters'][config.field]?.includes(option.key)"
                      >
                        {{option.label}} : {{option.doc_count}}
                      </ion-checkbox><br />
                    </ng-container>
                  </ion-list>
                </ng-container>
                <!-- <ng-container *ngIf="config.type === 'range'">
                  <app-range-filter [config]="config"></app-range-filter>
                </ng-container> -->
              </div>
            </ion-accordion>
          </ion-accordion-group>
        </ion-list>
      </div>
    </ion-content>
  </ion-menu>
  <div class="ion-page" id="doc-modal-content">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button menu="filter-menu"></ion-menu-button>
        </ion-buttons>
        <ion-buttons slot="start">
          <ion-button (click)="summarizeDocs()" fill="solid" color="primary">
            Summarize Page
          </ion-button>
        </ion-buttons>
        <ion-searchbar [debounce]="300" placholder="Search Documents" (ionInput)="onSearchInput($event)"></ion-searchbar>
      </ion-toolbar>
      <ion-toolbar *ngIf="summary || summaryLoading">
        <ion-progress-bar *ngIf="summaryLoading" type="indeterminate"></ion-progress-bar>
        <div class="ion-padding-top"><ion-title>Summary</ion-title></div>
        <div class="ion-padding"><ion-text>{{summary}}</ion-text></div>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div #tableRef class="doc-list-container">
        <div class="doc-list-body">
          <ng-container *ngIf="docsLoading">
            <ngx-skeleton-loader count="10"></ngx-skeleton-loader>
          </ng-container>
          <div class="docs-container" *ngIf="!docsLoading">
            <div *ngFor="let doc of hits" class="doc-wrapper">
              <div class="doc-container" [class.expanded]="doc?.expanded">
                <div class="row1">
                  <div class="title" (click)="onClickDocument(doc)" [class.with-link]="doc?.link?.length">{{doc?.title || 'No Title' | titlecase}}</div>
                </div>
                <div class="orgs" *ngIf="doc?.orgs?.length">
                  <div class="org" *ngFor="let org of doc?.orgs; let first=first;">
                    <ng-container *ngIf="!first">&bull;</ng-container>
                    <app-flag-icon [iso2]="org?.country"></app-flag-icon> |
                    {{org?.org | titlecase}} | {{countryService.getCountryName(org?.country)}}
                  </div>
                </div>
                <div class="row3">
                  <div class="doc-year">{{doc.year}}</div>&bull;
                  <div class="doc-type">{{docOriginLabels[doc.doc_origin]}}</div>&bull;
                  <div *ngIf="doc?.doc_origin.includes('grant')" class="award">{{doc.award_amount | tableValue: 'usd_'}}</div>
                  <div *ngIf="doc?.doc_origin === 'invention'">{{doc.award_amount | tableValue: 'usd_'}}</div>
                  <!-- <div class="country-flags">
                    <span *ngFor="let country of doc.iso2s" [ngClass]="['country-flag', 'flag-icon','flag-icon-' + iso2.toLocaleLowerCase()]"></span>
                  </div> -->
                </div>
                <div class="row2">
                  <div class="supercluster">{{doc?.supercluster_name}}</div>
                  <div class="cluster">{{doc?.cluster_name}}</div>
                </div>
                <div class="expanded-content" *ngIf="doc?.expanded">
                  <div class="abstract">{{doc?.abstract}}</div>
                </div>
                <div class="footer">
                  <div class="expand-toggle" (click)="doc.expanded = !doc?.expanded">
                    <span class="expand-toggle-label">
                      {{doc?.expanded ? 'Show Less' : 'Show More'}}
                    </span>
                    <cds-icon shape="angle" [direction]="doc?.expanded ? 'up' : 'down'"></cds-icon>
                    <!-- <mat-icon>{{doc?.expanded ? 'expand_less' : 'expand_more'}}</mat-icon> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <div class="paginator-container">
        <mat-paginator #paginator
          (page)="onPage($event)"
          [length]="total"
          [pageSize]="10"
          [pageIndex]="pageIndex"
          [showFirstLastButtons]="true"
          [pageSizeOptions]="[10, 25, 50, 100]"
        ></mat-paginator>
      </div>
    </ion-footer>
  </div>
</ion-split-pane>