import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomerService } from '@app/core/customer.service';
import { ModalService } from '@app/core/modal.service';
import { IonicModule, ModalController } from '@ionic/angular';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, RouterModule,],
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss'],
})
export class WelcomeModalComponent  implements OnInit {
  isTrialMode = false;
  constructor(
    private modalController: ModalController,
    public modalService: ModalService,
    public cs: CustomerService,
  ) { }

  ngOnInit() {
    if (this.cs.customerProducts && this.cs.customerProducts.length === 1 && this.cs.customerProducts[0].is_trial) {
      this.isTrialMode = true;
    }
  }

  cancel() {
    this.modalController.dismiss();
  }

  goToCheckout() {
    this.modalController.dismiss(
      {action: 'trial'}
    );
  }

  goToPlayground() {
    this.modalController.dismiss(
      {action: 'playground'}
    );
  }

  async updateSettings() {
    
  }

}
