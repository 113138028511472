
export interface IField {
  label: string;
  section: string;
  field: string;
  visible?: boolean;
  type: 'string' | 'number';
  dimension: boolean;
  measure: boolean;
  description: string;
}

const general_fields: { [field: string]: IField } = {
  // "group_id": {
  //   "label": "Group",
  //   "section": "General",
  //   "field": "group_id",
  //   "visible": false,
  //   "type": "string",
  //   "dimension": true,
  //   "measure": false,
  //   "description": "Technology Area"
  // },
  "cluster_relevance": {
    "label": "Relevance",
    "section": "General",
    "field": "cluster_relevance",
    "visible": true,
    "type": "string",
    "dimension": true,
    "measure": true,
    "description": "How relevant the cluster is to your search."
  },
  "supercluster_id": {
    "label": "Cluster ID",
    "section": "General",
    "field": "supercluster_id",
    "visible": false,
    "type": "string",
    "dimension": true,
    "measure": false,
    "description": ""
  },
  "supercluster_name": {
    "label": "Supercluster",
    "section": "General",
    "field": "supercluster_name",
    "visible": false,
    "type": "string",
    "dimension": true,
    "measure": false,
    "description": ""
  },
  "cluster_id": {
    "label": "Cluster ID",
    "section": "General",
    "field": "cluster_id",
    "visible": false,
    "type": "string",
    "dimension": true,
    "measure": false,
    "description": ""
  },
  "cluster_name": {
    "label": "Cluster",
    "section": "General",
    "field": "cluster_name",
    "visible": false,
    "type": "string",
    "dimension": true,
    "measure": false,
    "description": ""
  },
  "cluster_keywords": {
    "label": "Keywords",
    "section": "General",
    "field": "cluster_keywords",
    "visible": false,
    "type": "string",
    "dimension": true,
    "measure": true,
    "description": ""
  },
  "year": {
    "label": "Year",
    "section": "General",
    "field": "year",
    "visible": false,
    "type": "number",
    "dimension": true,
    "measure": true,
    "description": ""
  },
  "growth_type": {
    "label": "Growth",
    "section": "Growth Activity",
    "field": "growth_type",
    "visible": true,
    "type": "string",
    "dimension": true,
    "measure": true,
    "description": ""
  },
  "cos_sim": {
    "label": "Similarity Score",
    "section": "General",
    "field": "cos_sim",
    "visible": false,
    "type": "number",
    "dimension": true,
    "measure": true,
    "description": ""
  },
  "patent_cos_sim": {
    "label": "Patent Similarity Score",
    "section": "General",
    "field": "patent_cos_sim",
    "visible": false,
    "type": "number",
    "dimension": true,
    "measure": true,
    "description": ""
  },
}

const doc_count_fields: { [field: string]: IField } = {
  "doc_count_total": {
    "label": "Total Documents",
    "section": "Document Activity",
    "field": "doc_count_total",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Total number of documents (patents, publications, grants)"
  },
  "doc_count_total_org": {
    "label": "Total Documents",
    "section": "Document Activity",
    "field": "doc_count_total_org",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Total number of documents (patents, publications, grants) across all technology areas"
  },
  "doc_count_total_emerging": {
    "label": "Total ETech Documents",
    "section": "Document Activity",
    "field": "doc_count_total_emerging",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Total number of documents (patents, publications, grants) in emerging technologies"
  },
  "doc_count_grants_total": {
    "label": "Total Grants",
    "section": "Document Activity",
    "field": "doc_count_grants_total",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Number of Grants"
  },
  "doc_count_grants_total_org": {
    "label": "Total Grants",
    "section": "Document Activity",
    "field": "doc_count_grants_total_org",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Number of Grants across all technology areas"
  },
  "doc_count_grants_total_emerging": {
    "label": "Total ETech Grants",
    "section": "Document Activity",
    "field": "doc_count_grants_total_emerging",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Number of Grants across all emerging technology areas"
  },
  "doc_count_nsf_grant": {
    "label": "NSF Awards",
    "section": "Document Activity",
    "field": "doc_count_nsf_grant",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Number of NSF grants awarded"
  },
  "doc_count_nsf_grant_org": {
    "label": "NSF Grants",
    "section": "Document Activity",
    "field": "doc_count_nsf_grant_org",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Number of NSF grants awarded across all technology areas"
  },
  "doc_count_nsf_grant_emerging": {
    "label": "NSF ETech Grants",
    "section": "Document Activity",
    "field": "doc_count_nsf_grant_emerging",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Number of NSF grants awarded in ETechs"
  },
  "doc_count_sbir_grant": {
    "label": "SBIR Grants",
    "section": "Document Activity",
    "field": "doc_count_sbir_grant",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Number of SBIR Grants Awarded"
  },
  "doc_count_sbir_grant_org": {
    "label": "SBIR Grants",
    "section": "Document Activity",
    "field": "doc_count_sbir_grant_org",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Number of SBIR Grants Awarded across all Technology Areas"
  },
  "doc_count_sbir_grant_emerging": {
    "label": "SBIR ETech Grants",
    "section": "Document Activity",
    "field": "doc_count_sbir_grant_emerging",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Number of SBIR Grants Awarded across all ETechs"
  },
  "doc_count_dod_grant": {
    "label": "DoD Grants",
    "section": "Document Activity",
    "field": "doc_count_dod_grant",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Number of DoD grants awarded"
  },
  "doc_count_dod_grant_org": {
    "label": "DoD Grants",
    "section": "Document Activity",
    "field": "doc_count_dod_grant_org",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Number of DoD grants awarded across all technology areas"
  },
  "doc_count_dod_grant_emerging": {
    "label": "DoD ETech Grants",
    "section": "Document Activity",
    "field": "doc_count_dod_grant",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Number of DoD grants awarded in ETechs"
  },
  "doc_count_invention": {
    "label": "Patents",
    "section": "Document Activity",
    "field": "doc_count_invention",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Number of simple patent families"
  },
  "doc_count_invention_org": {
    "label": "Patents",
    "section": "Document Activity",
    "field": "doc_count_invention_org",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Number of simple patent families across all technology areas"
  },
  "doc_count_invention_emerging": {
    "label": "ETech Patents",
    "section": "Document Activity",
    "field": "doc_count_invention_emerging",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Number of simple patent families in emerging technologies"
  },
  "doc_count_pub": {
    "label": "Publications",
    "section": "Document Activity",
    "field": "doc_count_pub",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Total Count of Publications"
  },
  "doc_count_pub_emerging": {
    "label": "Publications",
    "section": "Document Activity",
    "field": "doc_count_pub_emerging",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Total Count of Publications in Emerging clustes across all technology areas"
  },
  "doc_count_pub_org": {
    "label": "Publications",
    "section": "Document Activity",
    "field": "doc_count_pub_org",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Total Count of Publications across all technology areas"
  },
  "doc_count_old": {
    "label": "Volume (Old)",
    "section": "Growth Activity",
    "field": "doc_count_old",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": ""
  },
  "doc_count_mid": {
    "label": "Volume (Mid)",
    "section": "Growth Activity",
    "field": "doc_count_mid",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": ""
  },
  "doc_count_new": {
    "label": "Volume (New)",
    "section": "Growth Activity",
    "field": "doc_count_new",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": ""
  },
}

const usd_fields:  { [field: string]: IField } = {
  "usd_grants_total": {
    "label": "Total Grant Funding",
    "section": "US Funding",
    "field": "usd_grant_total",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Grant Award Amount in USD"
  },
  "usd_grants_total_org": {
    "label": "Total Grant Funding",
    "section": "US Funding",
    "field": "usd_grants_total_org",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Grant Award Amount in USD across all tech areas"
  },
  "usd_grants_total_emerging": {
    "label": "Total ETech Grant Funding",
    "section": "US Funding",
    "field": "usd_grants_total_org",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Grant Award Amount in USD across all emerging tech areas"
  },
  "usd_sbir_grant": {
    "label": "SBIR Funding",
    "section": "US Funding",
    "field": "usd_sbir_grant",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "SBIR Award Amount in USD"
  },
  "usd_sbir_grant_org": {
    "label": "SBIR Funding",
    "section": "US Funding",
    "field": "usd_sbir_grant_org",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "SBIR Award Amount in USD across all tech areas"
  },
  "usd_sbir_grant_emerging": {
    "label": "SBIR Funding",
    "section": "US Funding",
    "field": "usd_sbir_grant_emerging",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "SBIR Award Amount in USD across all ETechs"
  },
  "usd_dod_grant": {
    "label": "Funding (DoD)",
    "section": "US Funding",
    "field": "usd_dod_grant",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "US Dept of Defense Award Amount in USD"
  },
  "usd_nsf_grant": {
    "label": "NSF Funding",
    "section": "US Funding",
    "field": "usd_nsf_grant",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "NSF Award Amount in USD"
  },
  "usd_total": {
    "label": "Total Funding",
    "section": "US Funding",
    "field": "usd_total",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Award Amount in USD"
  },
  "usd_invention": {
    "label": "Patent Inv. Cost",
    "section": "Patent Metrics",
    "field": "usd_invention",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Estimated cost of investment made into acquiring patents in USD"
  },
  // 'funding_score': {
  //   "label": "Funding Score",
  //   "section": "US Funding",
  //   "field": "funding_score",
  //   "visible": false,
  //   "type": "number",
  //   "dimension": false,
  //   "measure": true,
  //   "description": "A measure of an organization's research output relative to their grant funding. Higher score means high output per dollar relative to other organizations"
  // },
  'rank_funding_efficiency': {
    "label": "Funding Eff. Rank",
    "section": "US Funding",
    "field": "rank_funding_efficiency",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Orgs are ranked by their total document output relative to their total grant funding. Higher rank means high output per dollar relative to other organizations in the selection."
  },
  'rank_underfunded': {
    "label": "Underfunded Rank",
    "section": "US Funding",
    "field": "rank_underfunded",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Clusters are ranked by their growth in document volume relative to their total grant funding. Higher rank means that this cluster is likely underfunded relative to the other clusters in the selection."
  }
}

const pct_fields:  { [field: string]: IField } = {
  "pct_growth_invention_org": {
    "label": "Patent Growth (%)",
    "section": "Document Activity",
    "field": "pct_growth_invention_org",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "% growth in the number of simple patent families across all technology areas over the last 5 years relative to the previous 5 years"
  },
  "pct_growth_invention_org_emerging": {
    "label": "ET Patent Growth (%)",
    "section": "Document Activity",
    "field": "pct_growth_invention_org_emerging",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "% growth in the number of simple patent families that are in emerging technologies over the last 5 years relative to the previous 5 years"
  },
  "pct_invention_emerging": {
    "label": "ET Patent (%)",
    "section": "Document Activity",
    "field": "pct_invention_emerging",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "% of patent families that are in emerging technologies"
  },
  "pct_invention_emerging_all": {
    "label": "ET Patents - All Group (%)",
    "section": "Document Activity",
    "field": "pct_invention_emerging_all",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "% of patent families that are in emerging technologies across all technology areas"
  },
  "pct_invention_emerging_org": {
    "label": "ET Patents - All Groups(%)",
    "section": "Document Activity",
    "field": "pct_invention_emerging_org",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "% of patent families that are in emerging technologies across all technology groups"
  },
  "pct_cluster_count_emerging_total": {
    "label": "ETech %",
    "section": "Document Activity",
    "field": "pct_cluster_count_emerging_total",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "% of technologies that are emerging"
  },
  "pct_growth_full_total": {
    "label": "Overall Growth",
    "section": "Growth Activity",
    "field": "pct_growth_full_total",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "% growth by document volume in the last 5 years relative to the previous 5 years"
  },
  "pct_growth_recent_total": {
    "label": "Recent Growth",
    "section": "Growth Activity",
    "field": "pct_growth_recent_total",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "% growth by document volume in the last 5 years"
  },
  "pct_growth_recent": {
    "label": "Recent Growth",
    "section": "Growth Activity",
    "field": "pct_growth_recent",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "% growth by document volume in the last 5 years"
  },
  "pct_patent_value_cn": {
    "label": "% Patent Value (China)",
    "section": "Patent Valuation",
    "field": "pct_patent_value_cn",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": ""
  },
  "pct_patent_value_us": {
    "label": "% Patent Value (U.S.)",
    "section": "Patent Valuation",
    "field": "pct_patent_value_us",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": ""
  },


}

const metric_fields:  { [field: string]: IField } = {

}

const org_fields:  { [field: string]: IField } = {
  "org": {
    "label": "Organization",
    "section": "Organizations",
    "field": "org",
    "visible": false,
    "type": "string",
    "dimension": true,
    "measure": false,
    "description": "Company or Organization Name"
  },
  "org_type": {
    "label": "Org Type",
    "section": "Organizations",
    "field": "org_type",
    "visible": false,
    "type": "string",
    "dimension": true,
    "measure": false,
    "description": "Type of Organization (Private, Academic, Defense, Research Lab)"
  },
  "org_rank": {
    "label": "Org Rank",
    "section": "Organizations",
    "field": "org_rank",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": ""
  },
  "num_employees": {
    "label": "# Employees",
    "section": "Organizations",
    "field": "num_employees",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "# Employees"
  },
  "usd_market_cap": {
    "label": "Market Cap",
    "section": "Organizations",
    "field": "usd_market_cap",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Market Cap in USD"
  },
  "org_size": {
    "label": "Size",
    "section": "Organizations",
    "field": "org_size",
    "visible": false,
    "type": "string",
    "dimension": true,
    "measure": false,
    "description": "Organization Size (Small, Medium, or Large)"
  },
  "country": {
    "label": "Country",
    "section": "Organizations",
    "field": "country",
    "visible": false,
    "type": "string",
    "dimension": true,
    "measure": false,
    "description": "Country"
  },
  "iso2": {
    "label": "Country",
    "section": "Organizations",
    "field": "iso2",
    "visible": false,
    "type": "string",
    "dimension": true,
    "measure": false,
    "description": "Country"
  },
  "region": {
    "label": "Region",
    "section": "Organizations",
    "field": "region",
    "visible": false,
    "type": "string",
    "dimension": true,
    "measure": false,
    "description": "Geographic Region"
  },
  "sector": {
    "label": "Sector",
    "section": "Organizations",
    "field": "sector",
    "visible": false,
    "type": "string",
    "dimension": true,
    "measure": false,
    "description": "Sector"
  },
  "industry": {
    "label": "Industry",
    "section": "Organizations",
    "field": "industry",
    "visible": false,
    "type": "string",
    "dimension": true,
    "measure": false,
    "description": "Industry"
  },
  "univ": {
    "label": "University",
    "section": "Organizations",
    "field": "univ",
    "visible": false,
    "type": "string",
    "dimension": true,
    "measure": false,
    "description": "University or Academic Organization"
  },
  "sbir_org": {
    "label": "SBIR Awardee",
    "section": "Organizations",
    "field": "sbir_org",
    "visible": false,
    "type": "string",
    "dimension": true,
    "measure": false,
    "description": "SBIR Awardee"
  },
}

export const FieldMap: { [field: string]: IField } = {
  ...general_fields,
  ...doc_count_fields,
  ...usd_fields,
  ...pct_fields,
  ...metric_fields,
  ...org_fields,
  "cluster_count": {
    "label": "Total Clusters",
    "section": "Document Activity",
    "field": "cluster_count",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "The number of technology clusters in which this company has patented inventions"
  },
  "cluster_count_sig": {
    "label": "Total Clusters",
    "section": "Document Activity",
    "field": "cluster_count_sig",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "The number of technology clusters in which this organization has a significant number of inventions"
  },
  "cluster_count_emerging": {
    "label": "ETechs",
    "section": "Document Activity",
    "field": "cluster_count_emerging",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "The total number of emerging technologies that this organization has IP "
  },
  "cluster_count_emerging_sig": {
    "label": "Total Clusters",
    "section": "Document Activity",
    "field": "cluster_count_emerging_sig",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "The number of emerging technology clusters that this organization has a significant number of patents in"
  },
  "cluster_count_emerging_overlap": {
    "label": "Mutual ETechs",
    "section": "Document Activity",
    "field": "cluster_count_emerging_overlap",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "The number of technology clusters that both the selected organization and University/SBIR organization have patents in"
  },
  "etl_score": {
    "label": "ETL Score",
    "section": "Document Activity",
    "field": "etl_score",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "ETL Score"
  },
  "pct_doc_count_total_emerging": {
    "label": "ETech %",
    "section": "Document Activity",
    "field": "pct_doc_count_total_emerging",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Percentage of all documents that are related to emerging technologies"
  },
  "etl_rank": {
    "label": "ETL Rank",
    "section": "Document Activity",
    "field": "etl_rank",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": "Rank by ETL"
  },

  "xcl": {
    "label": "X",
    "section": "Map Coordinates",
    "field": "xcl",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": ""
  },
  "ycl": {
    "label": "Y",
    "section": "Map Coordinates",
    "field": "ycl",
    "visible": false,
    "type": "number",
    "dimension": false,
    "measure": true,
    "description": ""
  },

}

export const GetFieldDescription = (k: string) => FieldMap[k].description;
export const GetFieldLabel = (k: string) => {
  try {
    return FieldMap[k].label;
  } catch (err) {
    console.error(`Field ${k} not in FieldMap`)
    return '';
  }
}
export const GetFieldSection = (k: string) => {
  try {
    return FieldMap[k].section;
  } catch (err) {
    console.error(`Field ${k} not in FieldMap`)
    return '';
  }
}

export const FieldIsClickable = (c: string) => {
  return c.includes('doc_count_') || (c.includes('usd_') && !c.includes('total')) || ['org'].includes(c);
}
