import { Timestamp } from '@firebase/firestore';
import { StorageReference } from '@firebase/storage';
import { TCollection, Serializable } from './common.model';
export interface IAvatarMetadata {
  name: string;
  uid: string;
  collection: string;
}
export interface IEntity {
  uid: string; // firestore ID
  collection: TCollection;
  avatar?: StorageReference | string; // placeholder
  avatarMetadata?: IAvatarMetadata; // placeholder
  thumbnail?: StorageReference | string; // placeholder
  thumbnailMetadata?: IAvatarMetadata; // placeholder
  createdAt: Timestamp;
  updatedAt: Timestamp;
  modelVersion: number; // in case migration is needed
  seed: boolean; // dev
}

export enum avatarCollectionMap {
  users = 'users',
  reports = 'reports'
}
export class Entity extends Serializable implements IEntity {
  public static currentModelVersion = 1.0;
  public static fixedProperties = [
    'uid',
    'seed',
    'name',
    'creatorId',
    'creator',
    'collection',
    'modelVersion',
    'createdAt',
    'updatedAt',
    'queryMetadata',
  ];

  // Placeholders (should not ever be written to db)
  public static DeleteKeys = [
    'avatar',
    'avatarMetadata',
    'thumbnail',
    'thumbnailMetadata',
  ];
  public uid: string;
  public collection: TCollection;
  public avatar?: StorageReference | string = null;
  public avatarMetadata?: IAvatarMetadata;
  public thumbnail?: StorageReference | string = null;
  public thumbnailMetadata?: IAvatarMetadata;
  public createdAt = Timestamp.fromDate(new Date());
  public updatedAt = Timestamp.fromDate(new Date());
  public modelVersion = Entity.currentModelVersion;
  public seed = false;

  constructor(obj: Partial<Entity> = {}) {
    super();
    const {
      uid = null,
      collection = null,
      avatar = null,
      avatarMetadata = null,
      thumbnail = null,
      thumbnailMetadata = null,
      createdAt = Timestamp.fromDate(new Date()),
      updatedAt = Timestamp.fromDate(new Date()),
      modelVersion = Entity.currentModelVersion,
      seed = false
    } = obj;
    this.uid = uid;
    this.collection = collection;
    this.avatar = avatar;
    this.avatarMetadata = avatarMetadata;
    this.thumbnail = thumbnail;
    this.thumbnailMetadata = thumbnailMetadata;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.modelVersion = modelVersion;
    this.seed = seed;
    this.finalize();
  }

  public setId(uid: string) {
    this.uid = uid;
  }

  // converts any values from undefined to null (recursive)
  public static undefinedToNull = (obj: Partial<Entity | IEntity>) => {
    Object.getOwnPropertyNames(obj).forEach(k => {
      if (['component','chartController'].includes(k)) {
        // do nothing
      } else if (obj[k] === undefined || obj[k] === null) {
        obj[k] = null;
      } else if ( typeof(obj[k]) === 'object') {
        obj[k] = Entity.undefinedToNull(obj[k]);
      }
    });
    return obj;
  }

  public toJson() {
    return JSON.parse(JSON.stringify(this));
  }

  // Converts any class values to appropriate Firestore values
  public toDB() {
    const ret = super.toDB() as IEntity;
    // override firestore values
    ['createdAt','updatedAt'].forEach(k => {
      ret[k] = this[k];
    });
    if (!ret.uid && !ret.seed) {
      throw new Error('no uid');
    }
    Entity.DeleteKeys.forEach(k => {
      delete ret[k];
    });
    return Entity.undefinedToNull(ret);
  }

  public extractProperties(obj: Partial<IEntity>, props: string[]) {
    for (const prop of props) {
      if (obj && prop in obj) {
        if (!!obj[prop]) {
          this[prop] = obj[prop];
        } else if (obj[prop] === false) {
          this[prop] = false;
        } else {
          if (!this[prop]) this[prop] = null;
        }
      }
    }
    this.finalize();
  }

  public static processValues(value: any) {
    if (value === undefined) {
      return null;
    } else if (value === false) {
      return false;
    } else if (value === null) {
      return null;
    } else if (value._seconds && value._nanoseconds) {
      // if algolia timestamp, convert to firestore timestamp
      return new Timestamp(value._seconds, value._nanoseconds);
    } else if (value.seconds && value.nanoseconds) {
      // if algolia timestamp, convert to firestore timestamp
      return new Timestamp(value.seconds, value.nanoseconds);
    } else if (value instanceof Array) {
      return value;
    } else if (typeof(value) === 'object') {
      // if subobject, recursively process sub-objects
      const ret = {};
      Object.keys(value).forEach(k => {
        ret[k] = Entity.processValues(value[k]);
      });
      return ret;
    } else {
      return value;
    }
  }

  public finalize() {
    Object.keys(this).forEach(k => {
      this[k] = Entity.processValues(this[k]);
    });
  }

}



