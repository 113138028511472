import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableValue'
})
export class TableValuePipe implements PipeTransform {
  toTitleCase(str) {
    if (!str) return '';
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  transform(value: unknown, column: string, ...args: unknown[]): unknown {
    // console.log('table value pipe', value, column, args);
    const breakpoints = [['B',1000000000], ['M', 1000000], ['k', 1000], ['', 0]];
    if (column.startsWith('usd')) {
      if (value === 0) {
      }
      for (let [suffix, bp] of breakpoints) {
        if (value > bp) {
          return `$${(Math.round((value as number * 100 / (bp as number || 1))) / 100).toString()}${suffix}`
        }
      }
    } else if (column.startsWith('doc_count') || column.startsWith('cluster_count')) {
      for (let [suffix, bp] of breakpoints) {
        if (value > bp) {
          return `${(Math.round((value as number * 100 / (bp as number || 1))) / 100).toString()}${suffix}`
        }
      }
    } else if (column.startsWith('pct')) {
      return `${Math.round((value as number))}%`
    } else if (['org','country','region','univ'].includes(column)) {
      return this.toTitleCase(value);
    } else if (column === 'org_size') {
      return {'S': 'Small', 'M': 'Mid-Size', 'L': 'Large'}[value as string]
    } else {
      return value;
    }
  }

}
