<ion-header>
  <ion-toolbar>
    <ion-title>
      CHN Playground
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-item>
    <ion-label class="ion-text-wrap">
      CHN Playground is a sandbox environment where users can explore samples of our unique datasets and capabilities and gain early access to future products for free. 
    </ion-label>
  </ion-item>
  <ion-item>
    <ion-label class="ion-text-wrap">
      We are constantly updating and adding to the playground so be sure to check back to view new capabilities and get early-access to future products and releases.
    </ion-label>
  </ion-item>
</ion-content>
<ion-footer>
  <div class="buttons-container">
    <ion-button (click)="close()" color="success">Got it!</ion-button>
    <ion-button (click)="goToPlayground()" color="primary">View Playground</ion-button>
  </div>
</ion-footer>