import { Component, Input, OnInit } from '@angular/core';
import { IonNav, ItemReorderEventDetail, ModalController, NavController } from '@ionic/angular';
import { FieldMap, GetFieldLabel, IField } from '@app/models/app_models/fieldMap.model';
import { ColumnSelectorFieldListComponent } from '../column-selector-field-list/column-selector-field-list.component';
import { DemoService } from '@app/core/demo.service';
import { SearchService } from '@app/core/search.service';

@Component({
  selector: 'app-column-selector-home',
  templateUrl: './column-selector-home.component.html',
  styleUrls: ['./column-selector-home.component.scss'],
})
export class ColumnSelectorHomeComponent  implements OnInit {
  fieldMap = FieldMap;
  tableKey: string;
  getFieldLabel = GetFieldLabel;
  columnGroupMap: any;
  activeColumns: string[];

  constructor(
    private ionModal: ModalController,
    private nav: IonNav,
    public ss: SearchService
  ) { }

  ngOnInit() {
    this.tableKey = this.nav.rootParams.tableKey;
    this.columnGroupMap = this.nav.rootParams.columnGroupMap;
    this.activeColumns = this.nav.rootParams.activeColumns;
  }


  removeField(field: string) {
    const columns = this.activeColumns;
    const idx = columns.indexOf(field);
    if (idx > -1) {
      columns.splice(idx, 1);
    }
    this.ss.fieldDispatcher$.next({
      tableKey: this.tableKey,
      fields: this.activeColumns
    });
  }

  handleReorder(e: CustomEvent<ItemReorderEventDetail>) {
    const columns = this.activeColumns;
    columns.splice(e.detail.to, 0, columns.splice(e.detail.from, 1)[0]);
    this.ss.fieldDispatcher$.next({
      tableKey: this.tableKey,
      fields: this.activeColumns
    });
    e.detail.complete();
  }

  dismiss() {
    this.ionModal.dismiss();
  }

  onClickSection(section) {
    this.nav.push(ColumnSelectorFieldListComponent, {section}, {direction: 'forward', animated: true})
  }
}
