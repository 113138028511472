<ion-header>
  <ion-toolbar>
    <ion-title>Saved Searches</ion-title>
    <ion-buttons slot="end">
      <ion-button color="medium" (click)="cancel()">CANCEL</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ng-container *ngIf="searches?.length">
      <ion-item button (click)="onSelectClusterSearch(s)" *ngFor="let s of searches">
        <ion-label>
          <!-- <ion-icon (click)="ss.openRenameSearchModal(s)" class="rename-icon" name="create-outline" [matTooltip]="'Rename'"></ion-icon> -->
          <!-- &nbsp; -->
          {{(s?.name | titlecase) || s?.query_string}}
          <p class="timestamp">{{s?.updated_at | date: 'short'}}</p>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button  (click)="onDeleteSearch(s.uid)">
            <ion-icon style="color: red;" slot="icon-only" name="close-circle-outline" [matTooltip]="'Delete Search'"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ng-container>
  </ion-list>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()" color="danger">Cancel</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>