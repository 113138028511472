import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthActivateGuard } from './guards/auth-activate.guard';
import { AuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo, canActivate } from '@angular/fire/auth-guard';
import { RedirectHomeGuard } from './guards/redirect-home.guard';
import { FeatureActivateGuard } from './guards/feature-activate.guard';
import { AdminActivateGuard } from './guards/admin-activate.guard';

// const adminOnly = () => hasCustomClaim('admin');
// const belongsToAccount = (next) => hasCustomClaim(`account-${next.params.id}`);
// const redirectToLogin = redirectUnauthorizedTo(['login']);
// const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'public',
    loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [RedirectHomeGuard]
    // canActivate: [AuthActivateGuard],
    // data: {authGuardPipe: redirectLoggedInToHome}
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [RedirectHomeGuard]
    // canActivate: [AuthActivateGuard],
    // data: {authGuardPipe: redirectLoggedInToHome}
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthActivateGuard, AdminActivateGuard],
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthActivateGuard],
    // data: {authGuardPipe: redirectToLogin}
  },
  {
    path: 'tech-report',
    loadChildren: () => import('./modules/tech-report/tech-report.module').then(m => m.TechReportModule),
  },
  {
    path: 'demo',
    loadChildren: () => import('./modules/demo/demo.module').then(m => m.DemoModule),
    canActivate: [AuthActivateGuard, FeatureActivateGuard],
    data: {features_required: ['explorer']}
  },
  {
    path: 'explorer',
    loadChildren: () => import('./modules/explorer/explorer.module').then(m => m.ExplorerModule),
    canActivate: [AuthActivateGuard, FeatureActivateGuard],
    data: {features_required: ['explorer']}
  },
  {
    path: 'scout',
    loadChildren: () => import('./modules/org/org.module').then(m => m.OrgModule),
    canActivate: [AuthActivateGuard, FeatureActivateGuard],
    data: {features_required: ['scout']}
  },
  {
    path: 'innovators',
    loadChildren: () => import('./products/pioneer/pioneer.module').then(m => m.PioneerModule),
    canActivate: [AuthActivateGuard, FeatureActivateGuard],
    data: {features_required: ['scout']}
  },
  // {
  //   path: 'report',
  //   loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule),
  //   canActivate: [AuthActivateGuard],
  // },

  {
    path: 'settings',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
    canActivate: [AuthActivateGuard],
  },
  {
    path: 'dev',
    loadChildren: () => import('./modules/dev/dev.module').then(m => m.DevModule),
  },
  {
    path: 'playground',
    loadChildren: () => import('./modules/playground/playground.module').then(m => m.PlaygroundModule),
    canActivate: [AuthActivateGuard],
  }
  // {
  //   path: 'vectorsearch',
  //   loadComponent: () => import('./components/search/vectorsearch.component').then(mod => mod.VectorsearchComponent)
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
