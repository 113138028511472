import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, distinct, distinctUntilChanged, lastValueFrom, map, shareReplay, skipWhile, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { authState } from '@angular/fire/auth';
import { getAuth } from 'firebase/auth';
// import { AuthService } from './auth.service';



@Injectable({
  providedIn: 'root'
})
export class BackendService {
  jwt: string;
  constructor(
    private http: HttpClient,
    private auth: AuthService,
  ) {
    // refresh the JWT every 5 minutes to prevent expiration errors
    setInterval(() => {
      if (this.jwt) {
        this.get_jwt(true);
      }
    }, 5 * 60 * 1000)
  }

  get_jwt(force_refresh=false) {
    return new Promise((resolve, reject) => {
      if (this.jwt && !force_refresh) {
        resolve(this.jwt)
      } else {
        authState(getAuth()).pipe(skipWhile(user => !user)).subscribe(user => {
          user.getIdToken().then(jwt => {
            this.jwt = jwt;
            resolve(this.jwt);
          })
        })
      }
    })
  }


  async es(body: any, indices = 'techscouting_clusters') {
    const stream = this.http.post(`${environment.backendUrl}/es`, {
      body,
      indices
    });
    const ret = await lastValueFrom(stream);
    console.log('es response', ret);
    return ret;
  }

  async get_route<T>(route, params = {}) {
    await this.get_jwt();
    const stream = this.http.get(`${environment.backendUrl}/${route}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.jwt
      },
      params,
    });
    const ret = await lastValueFrom(stream);
    console.log(route, ' response', ret);
    return ret as T;
  }

  async post_route<T>(route: string, body = {}): Promise<T> {
    await this.get_jwt();
    console.log('posting route', route, body)
    // body = {
    //   ...body, 
    //   env: environment.build_env
    // }
    const stream = this.http.post(`${environment.backendUrl}/${route}`, body, {
      headers: {
        'Content-type':'application/json', 
        'Accept':'application/json',
        'Authorization': this.jwt
      }
    });
    try {
      const ret = await lastValueFrom(stream);
      console.log(route, ' response', ret);
      return ret as T;
    } catch (err) {
      console.log('HTTP ERROR', err.status, err);
      if (err.status === 498) {
        await this.get_jwt(true);
        alert('Token Expired Error caught')
        return await this.post_route<T>(route, body);
      }
      throw(err);
    }
  }

  async log_event<T>(event: any, ): Promise<T> {
    console.log('logging event', event);
    const headers = {
      'Content-type':'application/json', 
      'Accept':'application/json',
    }
    if (this.jwt) {
      headers['Authorization'] = this.jwt
    }
    const stream = this.http.post(`${environment.backendUrl}/log/log_event`, {event}, {
      headers
    });
    try {
      const ret = await lastValueFrom(stream);
      console.log("LOG RESPONSE" + JSON.stringify(ret));
      return ret as T;
    } catch (err) {
      console.log('HTTP ERROR', err.status, err);
    }
  }

}
