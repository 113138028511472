<ion-header>
  <ion-toolbar>
    <ion-title>CHN Explorer Cookie Policy</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-text style="font-size: 1.0rem; padding-top: 20px;">CHN Uses cookies to ensure you get the best user experience across all of our products. If you choose to decline cookies, you may be required to log in again when switching between CHN products.</ion-text>
</ion-content>
<ion-footer>
  <ion-button style="width: 100%;" color="success" (click)="cancel()">Got it!</ion-button>
</ion-footer>