<ion-header>
  <ion-toolbar>
    <ion-title>Search Menu</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content *ngLet="ss.currentSearch$ | async as currentSearch">


  <ng-container *ngIf="currentSearch">
    <ion-item *ngIf="!router.url.includes('explorer')" (click)="ss.clearCurrentSearch()" color="danger" button [disabled]="!currentSearch?.uid">
      <ion-buttons slot="start">
        <ion-icon name="close"></ion-icon>
      </ion-buttons>
      <ion-label>
        Clear Current Focus Area
      </ion-label>
    </ion-item>
    <ion-item (click)="ss.clusterSelection.clear()" [disabled]="!ss.clusterSelection.selected.length" color="danger" button>
      <ion-buttons slot="start">
        <ion-icon name="trash-bin"></ion-icon>
      </ion-buttons>
      <ion-label>
        Reset Cluster Selection
      </ion-label>
    </ion-item>
    <!-- <ion-item button>
      <ion-buttons slot="start">
        <ion-icon name="options-outline"></ion-icon>
      </ion-buttons>
      <ion-label>
        Edit Focus Area
      </ion-label>
    </ion-item> -->
    <ion-item *ngIf="currentSearch">
      <ion-buttons slot="start">
        <ion-icon name="create-outline"></ion-icon>
      </ion-buttons>
      <ion-label>Rename Focus Area: </ion-label>
      <ion-input [placeholder]="currentSearch.name" [ngModel]="currentSearch.name" (ionChange)="onUpdateName($event.detail.value)"></ion-input>
      <ion-buttons slot="end">
        <ion-button>
          <ion-icon color="success" name="checkmark" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-item>
  </ng-container>
  <ion-item button color="success" (click)="onNewSearch()">
    <ion-buttons slot="start">
      <ion-icon name="add"></ion-icon>
    </ion-buttons>
    <ion-label>
      Create New Focus Area
    </ion-label>
  </ion-item>
  
  <ion-list *ngIf="ss?.searches?.userSearches?.length">
    <ion-list-header>
      Recent Focus Areas
    </ion-list-header>
    <ion-item button *ngFor="let s of ss?.searches?.userSearches" (click)="onSelectSearch(s)">
      <ion-label>
        {{s.name}}    
      </ion-label>
    </ion-item>
  </ion-list>



</ion-content>