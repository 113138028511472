import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { getAuth, getIdTokenResult } from 'firebase/auth';
import { authState } from 'rxfire/auth';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, take, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AdminActivateGuard implements CanActivate {
  constructor(
    private router: Router,
  ) {
    console.log('admin guard constructor');
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return authState(getAuth()).pipe(
        tap(x => console.log('admin guard', x)),
        distinctUntilChanged((a, b) => a.uid === b.uid),
        map(cu => {
          if (!cu) {
            console.log('redirect data', next.data)
            console.log('admin-activate guard, no auth', cu);
            return false;
          } else {
            console.log('admin-activate guard, yes auth', cu);
            cu.getIdTokenResult().then((idTokenResult) => {
              console.log('idTokenResult', idTokenResult);
              if (!!idTokenResult.claims.admin) {
                console.log('admin-activate guard, yes admin', cu);
                return true;
              } else {
                console.log('admin-activate guard, no admin', cu);
                return false;
              }
            })
          }
          console.log('in guard, true', cu);
          return true;
        }),
      );
    }
}
