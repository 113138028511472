import { inject, Injectable } from '@angular/core';
import { TCollection } from "@app/models/common.model";
import { fromDoc, TEntity } from "@app/models/models.helpers";
import { doc, collection, CollectionReference, DocumentReference, Query, query, QueryConstraint } from "firebase/firestore";
import { map, switchMap, take } from "rxjs/operators";
import { collectionData, docData } from 'rxfire/firestore';
import { Firestore } from '@angular/fire/firestore';
@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  fs: Firestore = inject(Firestore);
  constructor(
  ) {
  }

  public async appInit() {
    return;
  }

  public colToEntity$ = <T extends TEntity>(
    col: TCollection | string, 
    queryConstraints: QueryConstraint[] = [],
  ) => {
    let q: Query<T>;
    if (queryConstraints.length) {
      q = query(collection(this.fs, col) as CollectionReference<T>, ...queryConstraints);
    } else {
      q = query(collection(this.fs, col) as CollectionReference<T>);
    }
    return collectionData(q).pipe(
      switchMap(docs => {
        return Promise.all(docs.map(async document => {
          if (!doc) return null;
          try {
            const ent = fromDoc<T>(document as T);
            return ent;
          } catch (err) {
            throw new Error(`${err}`);
          }
        }))
      })
    )
  }

  public getCollection = <T extends TEntity>(
    col: TCollection,
    queryConstraints: QueryConstraint[] = [],
  ) => {
    return this.colToEntity$<T>(col, queryConstraints).pipe(take(1)).toPromise();
  }


  public docToEntity$ = <T extends TEntity>(path: string) => {
    return docData(doc(this.fs, path) as DocumentReference<T>).pipe(
      map(document => fromDoc<T>(document as T))
    )
  }

  public getDoc = <T extends TEntity>(path: string) => {
    return this.docToEntity$<T>(path).pipe(take(1)).toPromise();
  }

}
