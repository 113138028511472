import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalService } from '@app/core/modal.service';
import { NgLetModule } from '@app/directives/ng-let/ng-let.module';
import { IonicModule, ModalController } from '@ionic/angular';

@Component({
  standalone: true,
  imports: [IonicModule, CommonModule, NgLetModule, ],
  selector: 'app-signup-details-modal',
  templateUrl: './signup-details-modal.component.html',
  styleUrls: ['./signup-details-modal.component.scss'],
})
export class SignupDetailsModalComponent  implements OnInit {

  constructor(
    private modalController: ModalController,
    public ms: ModalService,
  ) { }

  ngOnInit() {}

  close() {
    this.modalController.dismiss();
  }


}
